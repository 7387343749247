import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import Select from "react-select";
import { ProgressSpinner } from "primereact/progressspinner";

const CreateShape = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [shapeTypes, setShapeTypes] = useState([]);
  const { checkRequiredFields } = useRequiredFields();

  useEffect(() => {
    const fetchShapeTypes = async () => {
      try {
        // const res = await Http.get("/shapeTypeSelect2"); // Fetch shape types from the endpoint
        // const options = res.data.map(shape => ({
        //   value: shape.value,
        //   label: shape.label,
        // }));
        // setShapeTypes(options);

        const res1 = await Http.get("/last-code-increment-by-one/shapes/code/SH");
        setInputs({
          code: res1.data,
          status: { value: "active", label: "Active" }, // Default status
        });
      } catch (error) {
        console.error("Error fetching shape types:", error);
      }
    };

    fetchShapeTypes();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleShapeTypeChange = (option) => {
    setInputs((values) => ({ ...values, shape_type_id: option.value }));
  };

  const handleStatusChange = (option) => {
    setInputs((values) => ({ ...values, status: option }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      setLoading(true); // Set loading to true while waiting for the response
      Http.post("/createShape", {
        ...inputs,
        status: inputs.status.value, // Send only the value of the selected status
      })
        .then((res) => {
          navigate("/shape", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        })
        .finally(() => {
          setLoading(false); // Reset loading state after the request
        });
    }
  };

  // Define options for status
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Shape</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Shape Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Shape Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Code<i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                      placeholder="Input Shape Code"
                      data-required
                    />
                  </div>
                  {/* <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Shape Type <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="shape_type_id"
                      options={shapeTypes} // Populate options from state
                      onChange={handleShapeTypeChange} // Handle dropdown change
                    />
                  </div> */}
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Status <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="status"
                      options={statusOptions} // Options for active/inactive
                      value={inputs.status || null}
                      onChange={handleStatusChange} // Handle status dropdown change
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateShape;
