import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import ReactDropdown from "../../../Common/ReactDropdown";

const Edit = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const { id } = useParams();
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/editLocation/" + id);
        setInputs({
          name: res.data.name,
          started_from: res.data.started_from,
          closed_from: res.data.closed_from,
          status: res.data.status,
        });
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/updateLocation/" + id, inputs)
        .then((res) => {
          navigate("/companylocation", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };
  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: prop ? e.value[prop] : e.value,
    }));
  };

  const findSelectedOption = (options, value, key) => {
    return options.find((item) => item[key] === value);
  };

  const status = [
    { statusName: "Active", statusValue: "active" },
    { statusName: "Inactive", statusValue: "inactive" },
  ];

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Location</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Location Form</h6>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Started From <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="started_from"
                      value={inputs.started_from || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold">Closed From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="closed_from"
                      value={inputs.closed_from || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Status <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="status"
                      onChange={handleChange}
                    >
                      <option
                        value="active"
                        selected={inputs.status === "active" ? true : false}
                      >
                        Active
                      </option>
                      <option
                        value="inactive"
                        selected={inputs.status === "inactive" ? true : false}
                      >
                        inactive
                      </option>
                    </select> */}
                    <ReactDropdown
                      options={status}
                      optitle={"statusName"}
                      val={findSelectedOption(status,inputs.status,"statusValue")}
                      onChange={reactDropdownHandler("status", "statusValue")}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
