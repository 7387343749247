import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import Swal from "sweetalert2";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const DailyProductionReport = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const [inputs, setInputs] = useState({
    query_date: getCurrentDate(),
  });

  function getCurrentDate() {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fetchData = () => {
    Http.get("/report-leave-report").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const formSubmit = () => {
    Http.get(
      "/movement-slip?from=" +
        inputs.from +
        "&to=" +
        inputs.to +
        "&staff=" +
        inputs.hr_staff_basic_info_id
    ).then((res) => {
      setReceiveData(res.data);
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Daily Production Report</h5>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-lg">
                      <input
                        type="date"
                        className="form-control margin-bottom-on-mobile"
                        name="from"
                        value={inputs.query_date || ""}
                        onChange={handleChange}
                        placeholder="From Date"
                      />
                    </div>
                    <div className="col-lg">
                      <button
                        className="btn btn-primary btn-block mg-b-10"
                        onClick={formSubmit}
                      >
                        <i className="fa fa-send mg-r-10"></i> Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h5 className="text-uppercase text-sm font-weight-bolder">
                      Artisan Ceramics Limited
                    </h5>
                    <p>
                      House-16, Road-07, Sector-01, Uttara, Dhaka
                      <br />
                      <b>Daily Production Report</b>
                      <br />
                      (BKL)
                    </p>
                  </div>
                  <div className="table-responsive p-0 pb-2">
                    <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 _datatable">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            S/N
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Production Name
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Shape
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Code No.
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Opening Balance
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Received From Casting & Finishing
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Total (5+6)
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Green Loss
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Biscuit Loss
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Transfered To Glaze
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Total (9+10)
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Closing Balance
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            31 cm Round Plate
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            02
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            31 cm Round Plate (Red)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            03
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            28 cm Dinner Plate (SMB)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            04
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            27 cm Dinner Plate
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            05
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            27 cm Dinner Plate (Red)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            06
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            27 cm Dinner Plate (Olive)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Art-01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></td>

                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            39
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            509
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            548
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            -
                          </td>
                        </tr>
                        {/* {receiveData &&
                          receiveData.map((item, index) => (
                            <>
                              <tr key={item.id}>
                                <td className="text-xs">{index + 1}</td>
                                <td className="text-xs" colSpan={13}>
                                  <b>{item.dept_name}</b>
                                </td>
                              </tr>
                              {item.staff.map((item2, index2) => (
                                <tr key={item2.id}>
                                  <td className="text-xs"></td>
                                  <td className="text-xs">{item2.name}</td>
                                  <td className="text-xs">{item.casual}</td>
                                  <td className="text-xs">{item.sick}</td>
                                  <td className="text-xs">{item.earn}</td>
                                  <td className="text-xs">{item.others}</td>
                                  <td className="text-xs">{item2.casual}</td>
                                  <td className="text-xs">{item2.sick}</td>
                                  <td className="text-xs">{item2.earn}</td>
                                  <td className="text-xs">{item2.others}</td>
                                </tr>
                              ))}
                            </>
                          ))} */}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            colSpan={4}
                          >
                            Total
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            120
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            2016
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            60
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            60
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            120
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            120
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            120
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            120
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyProductionReport;
