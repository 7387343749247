import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const CreatePlanning = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);

  useEffect(() => {
    initializeDates();
    fetchInitialData();
    $("body").addClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resWorkOrders = await Http.get(
        "/production-work-order?expire_check=1"
      );
      setWorkOrders(resWorkOrders.data);
      const resInvoiceNo = await Http.get(
        "/last-code-increment-by-one/plannings/receipt_no/PL"
      );
      setInputs((values) => ({
        ...values,
        receipt_no: resInvoiceNo.data,
      }));
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/production-work-order/${id}`);
      const hasDuplicates = list.some((group) => group.id === response.data.id);

      if (hasDuplicates) {
        Swal.fire(
          "Warning!",
          "Same work order is already in the list!",
          "warning"
        );
      } else {
        setList((prev) => [...prev, response.data]);
      }
    } catch (error) {
      console.error("Error fetching work order:", error);
      Swal.fire("Error!", "Failed to add work order.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeGroup = (groupIndex) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the entire work order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the entire group at the specified index
        const updatedList = list.filter((_, i) => i !== groupIndex);
        setList(updatedList);
        Swal.fire("Deleted!", "The group has been removed.", "success");
      }
    });
  };
  const fetchProductsByWorkOrderId = async (workOrderId) => {
    setItemLoading(true);
    try {
      const res = await Http.get(`/production-work-order/${workOrderId}`);
      setList(res.data.items);
    } catch (error) {
      console.error("Error fetching work order List:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch List for the selected work order.",
        "error"
      );
    } finally {
      setItemLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));

    // Check if production_work_order_id has been selected
    if (name === "production_work_order_id" && value) {
      await fetchProductsByWorkOrderId(value);
    }
  };

  const selectHandleChange = async (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const { value } = selectedOption;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, groupIndex, itemIndex, availableQty) => {
    const { name, value } = e.target;
    let input_qty = parseInt(value) || 0;

    setList((prevList) => {
      const updatedList = [...prevList];
      const item = updatedList[groupIndex].items[itemIndex];
      item[name] = Math.max(0, Math.min(input_qty, availableQty));
      return updatedList;
    });
    if (availableQty >= input_qty) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [`input_qty_${groupIndex}_${itemIndex}`]: input_qty,
      }));
    } else {
      Swal.fire(
        "Warning!",
        "You can't add more than available quantity! Available Quantity Is = " +
          availableQty,
        "warning"
      );
      setInputs((prevInputs) => ({
        ...prevInputs,
        [`input_qty_${groupIndex}_${itemIndex}`]: availableQty,
      }));
    }
  };

  const initializeDates = () => {
    const today = new Date();
    const nextMonthFirstDay = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      2
    ); // First day of next month
    const nextMonthLastDay = new Date(
      today.getFullYear(),
      today.getMonth() + 2,
      1
    ); // Last day of next month

    setInputs((values) => ({
      ...values,
      start_date: nextMonthFirstDay.toISOString().split("T")[0], // Set to 01/11/2024
      end_date: nextMonthLastDay.toISOString().split("T")[0], // Set to 30/11/2024
    }));
  };

  const formSubmit = () => {
    inputs.items = list;
    Http.post("/planning", inputs)
      .then((res) => {
        navigate("/planning", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [
    {
      field: (WO) => {
        return WO.receipt_no + " / " + WO.work_order_date;
      },
      header: "Work Order",
    },
    { field: "company.name", header: "Company" },
  ];
  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Work Plan</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Create Work Plan
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Work Planning No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Invoice No"
                    />

                    {displayError("receipt_no")}
                  </div>

                  <div className="col-lg">
                    <label>
                      Start Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="start_date"
                      value={inputs.start_date || ""}
                      onChange={handleChange}
                      placeholder="Input Start Date"
                    />

                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      End Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      value={inputs.end_date || ""}
                      onChange={handleChange}
                      placeholder="Input End Date"
                    />

                    {displayError("date")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Remarks <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Work Orders Items in the Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Shape
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Order Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Previous Production Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Qty
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={6}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Work Orders for Work Plan
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {list.map((group, groupIndex) => (
                            <React.Fragment key={`group-${groupIndex}`}>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-start font-weight-bold"
                                >
                                  {group.company.name} #{group.receipt_no} (
                                  {group.work_order_date} to{" "}
                                  {group.delivery_date})
                                  <button
                                    className="btn btn-danger btn-sm float-right"
                                    onClick={() => removeGroup(groupIndex)}
                                  >
                                    <i className="fa fa-trash"></i> Remove Work
                                    Order
                                  </button>
                                </td>
                              </tr>

                              {/* Rows for Each Item in the Group */}
                              {group.items.map((item, itemIndex) => (
                                <tr key={`item-${groupIndex}-${itemIndex}`}>
                                  <td>{item.product?.name || ""}</td>
                                  <td>{item.shape?.name || ""}</td>
                                  <td>{item.qty || 0}</td>
                                  <td>{item.prev_qty || 0}</td>
                                  <td>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="input_qty"
                                      value={
                                        inputs[
                                          `input_qty_${groupIndex}_${itemIndex}`
                                        ] ?? item.qty
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          groupIndex,
                                          itemIndex,
                                          item.qty - (item.prev_qty || 0)
                                        )
                                      }
                                      placeholder="Qty"
                                    />
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Work Orders
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={workOrders}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePlanning;
