import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const View = () => {
  const [receiveData, setReceiveData] = useState({});
  const [shapes, setShapes] = useState([]); // For storing shapes from the API
  const [shapeTypes, setShapeTypes] = useState([]); // For storing shape types from the API
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" }
  ]);

  useEffect(() => {
    fetchData();
    fetchShapesAndTypes(); // Fetch shape and shape type data
  }, [id]);

  const fetchData = () => {
    Http.get(`/requisition-art/${id}`)
      .then((res) => {
        setReceiveData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        Swal.fire("Error!", "Failed to fetch requisition details.", "error");
      });
  };

  const fetchShapesAndTypes = async () => {
    try {
      const resShapes = await Http.get("/shape");
      const resShapeTypes = await Http.get("/shapetype");
      setShapes(resShapes.data); // Set the shapes data
      setShapeTypes(resShapeTypes.data); // Set the shape types data
    } catch (err) {
      console.error("Error fetching shapes or shape types:", err);
      Swal.fire("Error!", "Failed to fetch shape or shape type details.", "error");
    }
  };

  const getShapeNameById = (shapeId) => {
    const shape = shapes.find((shape) => shape.id === shapeId);
    return shape ? shape.name : "N/A";
  };

  const getShapeTypeNameById = (shapeTypeId) => {
    const shapeType = shapeTypes.find((type) => type.id === shapeTypeId);
    return shapeType ? shapeType.name : "N/A";
  };

  const getDepartmentNameById = (departmentId) => {
    const department = departments.find((dept) => dept.id === departmentId);
    return department ? department.name : "N/A";
  };

  const dataList = receiveData.items || [];

  const print = () => {
    window.print();
  };

  return (
    <div>
      <LoaderComponent loading={loading} />
      <style>
        {`
          @media print {
              body * { visibility: hidden; font-size:10px;}
              .no-print { visibility: hidden !important; }
              #printable-content, #printable-content * { visibility: visible; }
              #printable-content { position: absolute; left: 0; top: 0; width: 100%; }
              .main-card { padding: 0px; }
              .am-pagebody { padding: 0px; }
              .am-mainpanel { margin-top: 0px; }
              .signature_section .table td{ border-bottom: 0;}
          }
          .main-card { padding: 40px; }
        `}
      </style>

      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Requisition Details</h5>
        </div>

        <div className="am-pagebody">
          <div className="card card-primary card-outline">
            <div className="card-header noPrint">
              <div className="row">
                <div className="col-md-7">
                  <h4 className="card-title">Receipt No - {receiveData.receipt_no}</h4>
                </div>
                <div className="col-md-5 text-right">
                  <button className="btn btn-default" onClick={print}>
                    <i className="fa fa-print"></i>
                    &nbsp;Print
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body" id="printable-content">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td>Work Order: {receiveData.production_work_order_id}</td>
                        <td>Copy Type: {receiveData.copy_type}</td>
                      </tr>
                      <tr>
                        <td>Date: {receiveData.date}</td>
                        <td>Receive Date: {receiveData.receive_date}</td>
                      </tr>
                      <tr>
                        <td>From Department: {getDepartmentNameById(receiveData.from_department)}</td>
                        <td>To Department: {getDepartmentNameById(receiveData.to_department)}</td>
                      </tr>
                      <tr>
                        <td colSpan="2">Remarks: {receiveData.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product</th>
                        <th>Unit</th>
                        <th>Shape</th>
                        <th>Shape Type</th>
                        <th>In Hand</th>
                        <th>In Pipeline</th>
                        <th>On Order</th>
                        <th>Req. Qty</th>
                        <th>Mould Qty Per Set</th>
                        <th>Req. Time</th>
                        <th>Est. Unit Price</th>
                        <th>Est. Total Price</th>
                        <th>Avg. Monthly Cons.</th>
                        <th>Decor.</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.product.name}</td>
                          <td>{item.unit_id}</td>
                          <td>{getShapeNameById(item.shape_id)}</td>
                          <td>{getShapeTypeNameById(item.shape_type_id)}</td>
                          <td>{item.in_hand_qty}</td>
                          <td>{item.in_pipeline_qty}</td>
                          <td>{item.on_order_qty}</td>
                          <td>{item.required_qty}</td>
                          <td>{item.mould_qty_per_set}</td>
                          <td>{item.required_time}</td>
                          <td>{item.estimated_unit_price}</td>
                          <td>{item.estimated_total_price}</td>
                          <td>{item.avg_monthly_consumption}</td>
                          <td>{item.decoration}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Prepared By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Checked By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Approved By
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
