import React from "react";
import { Link } from "react-router-dom";

const Production = ({ role, onMenuClick, active }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/receipe" ||
            active === "/receipe-factory" ||
            active === "/machineries" ||
            active === "/factory-machineries" ||
            active === "/requisition-production" ||
            active === "/department-requisition-jcg" ||
            active === "/issue" ||
            active === "/production" ||
            active === "/factory-return" ||
            active === "/depo-transfer" ||
            active === "/factory-transfer"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Production</span>
        </Link>
        <ul className="nav-sub">
          {(role === "admin" || role === "supply_chain") && (
            <>
              {/* <li className="nav-item">
                  <Link
                    to="/machineries"
                    className={`nav-link ${
                      active === "/machineries" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/machineries")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Machineries
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/factory-machineries"
                    className={`nav-link ${
                      active === "/factory-machineries" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/factory-machineries")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Factories Machineries
                  </Link>
                </li> */}
              <li className="nav-item">
                <Link
                  to="/receipe"
                  className={`nav-link ${
                    active === "/receipe" ? "active" : ""
                  }`}
                  onClick={() => onMenuClick("/receipe")}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>
                  &nbsp;&nbsp; Receipe
                </Link>
              </li>
            </>
          )}
          {role != "lab" && (
            <>
              {(role === "body" ||
                role === "mould" ||
                role === "sagger" ||
                role === "pstore" ||
                role === "gstore") && (
                <li className="nav-item">
                  <Link
                    to="/requisition-production"
                    className={`nav-link ${
                      active === "/requisition-production" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/requisition-production")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Store Requisition
                    <span className="badge bg-danger ms-2">0</span>
                  </Link>
                </li>
              )}
              {role !== "body" || role !== "gstore" || role !== "pstore"||role !== "dstore" && (
                <li className="nav-item">
                  <Link
                    to="/department-requisition-jcg"
                    className={`nav-link ${
                      active === "/department-requisition-jcg" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/department-requisition-jcg")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Requisition Department
                  </Link>
                </li>
              )}
              {(role === "admin" ||
                role === "body" ||
                role === "gstore" ||
                // role === "dstore" ||
                // role === "pstore" ||
                role === "mould" ||
                role === "sagger" ||
                role === "decoration_local" ||
                role === "decoration_export" ||
                role === "packing_local" ||
                role === "packing_export") && (
                <li className="nav-item">
                  <Link
                    to="/issue"
                    className={`nav-link ${
                      active === "/issue" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/issue")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Issue
                  </Link>
                </li>
              )}
              {(role === "admin" ||
                role === "body" ||
                // role === "gstore" ||
                role === "dstore" ||
                role === "pstore" ||
                role === "mould" ||
                role === "sagger") && (
                // role === "decoration_local" ||
                // role === "decoration_export" ||
                // role === "packing_local" ||
                // role === "packing_export"
                <li className="nav-item">
                  <Link
                    to="/production"
                    className={`nav-link ${
                      active === "/production" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/production")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Production
                  </Link>
                </li>
              )}
              {/* {role !== "dstore" &&
                role !== "pstore" &&
                role !== "gstore" &&
                role !== "depot" && (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/factory-transfer"
                        className={`nav-link ${
                          active === "/factory-transfer" ? "active" : ""
                        }`}
                        onClick={() => onMenuClick("/factory-transfer")}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                        &nbsp;&nbsp; Factory Transfer
                      </Link>
                    </li>
                  </>
                )} */}
              {(role === "admin" ||
                role === "body" ||
                role === "jigger" ||
                role === "casting" ||
                role === "finishing" ||
                role === "bk" ||
                role === "glazing" ||
                role === "gk" ||
                role === "selection" ||
                role === "decoration_local" ||
                role === "decoration_export" ||
                role === "dfw" ||
                role === "packing_local" ||
                role === "packing_export") && (
                <li className="nav-item">
                  <Link
                    to="/factory-transfer"
                    className={`nav-link ${
                      active === "/factory-transfer" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/factory-transfer")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Department Transfer
                  </Link>
                </li>
              )}
              {(role === "admin" ||
                role === "body" ||
                role === "jigger" ||
                role === "casting" ||
                role === "finishing" ||
                role === "glazing" ||
                // role === "gk" ||
                role === "selection" ||
                role === "decoration_local" ||
                role === "decoration_export" ||
                // role === "dfw" ||
                role === "packing_local" ||
                role === "packing_export") && (
                <li className="nav-item">
                  <Link
                    to="/factory-return"
                    className={`nav-link ${
                      active === "/factory-return" ? "active" : ""
                    }`}
                    onClick={() => onMenuClick("/factory-return")}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                    &nbsp;&nbsp; Department Return
                  </Link>
                </li>
              )}
              {role !== "dstore" &&
                role !== "pstore" &&
                role !== "gstore" &&
                role !== "depot" && (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/damage-return"
                        className={`nav-link ${
                          active === "/damage-return" ? "active" : ""
                        }`}
                        onClick={() => onMenuClick("/damage-return")}
                      >
                        <i className="fa fa-clone" aria-hidden="true"></i>
                        &nbsp;&nbsp; Damage Return
                      </Link>
                    </li>
                  </>
                )}
            </>
          )}
        </ul>
      </li>
    </>
  );
};

export default Production;
