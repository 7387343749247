import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [staff, setStaff] = useState({});
  const [authData, setAuthData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedStaff, setCheckedStaff] = useState([]);
  const date = new Date();
  const month = date.getMonth();
  const [attendanceGiven, setAttendanceGiven] = useState(false);
  const year = date.getFullYear();
  
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState({
    month: ("0" + (date.getMonth() + 1)).slice(-2),
    year: year,
    status: "active",
    from: "",
    to: "",
    hr_staff_basic_info_id: "",
  });

  const [checkedState, setCheckedState] = useState({});
  const [error, setError] = useState({});

  // const fetchData = () => {
  //   setLoading(true);
  //   const dateObj = new Date();
  //   const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  //   const day = ("0" + dateObj.getDate()).slice(-2);
  //   const year = dateObj.getFullYear();
  //   const date = `${year}-${month}-${day}`;
  //   const dateTo = `${year}-${month}-${day}`;

  //   setInputs((values) => ({ ...values, ["from"]: date }));
  //   setInputs((values) => ({ ...values, ["to"]: dateTo }));

  //       Http.get("/attendance?from=" + date + "&to=" + dateTo).then((res) => {
  //         setReceiveData(res.data);

  //         var arr = [];
  //         res.data.map((item, index) =>
  //           item.late == 1 && item.salary == 0 ? (arr[item.id] = false) : ""
  //         );

  //         setCheckedState(arr);
  //       });

  //       Http.get("/staff2").then((res) => {
  //         setStaff(res.data);
  //       });

  //       Http.get("/login-staff-detail").then((res) => {
  //         setAuthData(res.data);
  //       });
  // };

  const fetchData = async () => {
    setLoading(true);
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    const date = `${year}-${month}-${day}`;
  
    // Setting inputs for 'from' and 'to' dates
    setInputs((values) => ({ ...values, from: date, to: date }));
  
    try {
      const [attendanceRes, staffRes, authRes] = await Promise.all([
        Http.get(`/attendance?from=${date}&to=${date}`),
        Http.get("/staff2"),
        Http.get("/login-staff-detail")
      ]);
  
      setReceiveData(attendanceRes.data);
  
      // Process the attendance data to determine checked state
      const arr = [];
      attendanceRes.data.forEach((item) => {
        if (item.late === 1 && item.salary === 0) {
          arr[item.id] = false;
        }
      });
      setCheckedState(arr);
  
      setStaff(staffRes.data);
      setAuthData(authRes.data);

      Http.get("/me").then((res) => {
        setAttendanceGiven(res.data.data.attendance_given_or_not);
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
  
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const rejectLate = (id, status, msg) => {
    Http.get(
      "/late_attendance_reject/" +
        status +
        "/" +
        id +
        "/" +
        inputs.month +
        "/" +
        inputs.year
    ).then((res) => {
      setReceiveData(res.data);
      Swal.fire(
        "Late Attendance " + msg + "!",
        "Late Attendance has been " + msg,
        "success"
      );
    });
  };
  const approveDistance = (id, status) => {
    Http.get(
      "/attendance_approve_distance/" +
        status +
        "/" +
        id +
        "/" +
        inputs.month +
        "/" +
        inputs.year
    ).then((res) => {
      setReceiveData(res.data);
      Swal.fire(
        "Attendance Distance Accepted!",
        "Attendance Distance has been Accepted.",
        "success"
      );
    });
  };
  const rejectDistance = (id, status) => {
    Http.get(
      "/attendance_approve_distance/" +
        status +
        "/" +
        id +
        "/" +
        inputs.month +
        "/" +
        inputs.year
    ).then((res) => {
      setReceiveData(res.data);
      Swal.fire(
        "Attendance Distance Rejected!",
        "Attendance Distance has been Rejected.",
        "success"
      );
    });
  };
  const formSubmit = () => {
    Http.get(
      "/attendance?from=" +
        inputs.from +
        "&to=" +
        inputs.to +
        "&staff=" +
        inputs.hr_staff_basic_info_id
    ).then((res) => {
      setReceiveData(res.data);

      var arr = [];
      res.data.map((item, index) =>
        item.late == 1 && item.salary == 0 ? (arr[item.id] = false) : ""
      );

      setCheckedState(arr);
    });
  };

  const checkedAccept = () => {
    inputs.staffList = checkedState;
    Http.post("/late_attendance_reject_multiple", inputs)
      .then((res) => {
        setReceiveData(res.data);
        Swal.fire(
          "Late Attendance Accepted!",
          "Late Attendance has been Accepted.",
          "success"
        );
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const checked = () => {
    setCheckedAll(true);
    const staffList = [];
    receiveData.map((item, index) =>
      item.late == 1 && item.salary == 0 ? (staffList[item.id] = true) : false
    );
    setCheckedState(staffList);
  };

  const unChecked = () => {
    setCheckedAll(false);
    const staffList = [];
    setCheckedState(staffList);
  };

  const checkedSingleChkBox = (e, position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? e.target.checked : item
    );
    setCheckedState(updatedCheckedState);
  };

  // Format the data
  const formattedData = receiveData.map((item) => {
    const checkinDistance = parseFloat(item.checkin_distance) || 0;
    const checkoutDistance = parseFloat(item.checkout_distance) || 0;

    return {
      ...item,
      formatted_checkin_distance:
        checkinDistance < 1
          ? (checkinDistance * 1000).toFixed(3) + " Meter"
          : checkinDistance.toFixed(3) + " KM",
      formatted_checkout_distance:
        checkoutDistance < 1
          ? (checkoutDistance * 1000).toFixed(3) + " Meter"
          : checkoutDistance.toFixed(3) + " KM",
      formatted_start_time: new Date(
        `2000-01-01T${item.start_time}`
      ).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }),
      formatted_end_time: item.end_time
        ? new Date(`2000-01-01T${item.end_time}`).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })
        : "",
      movement_slip_links:
        item.movement_slip.length > 0
          ? item.movement_slip.map((data, index) => (
              <Link
                key={index}
                to={`/movement-slip-view/${data.id}`}
                className="btn btn-info btn-sm ms-1"
                target="_blank"
              >
                <i className="fa fa-list" aria-hidden="true"></i>
              </Link>
            ))
          : "",
    };
  });

  // Define columns
  const cols = [
    { field: "date", header: "Date" },
    { field: "staff.name", header: "Name" },
    { field: "formatted_checkin_distance", header: "Checkin Distance" },
    { field: "formatted_checkout_distance", header: "Checkout Distance" },
    { field: "formatted_start_time", header: "Checkin" },
    { field: "formatted_end_time", header: "Checkout" },
    { field: "remarks", header: "Remarks" }, // Assuming there's a remarks field
    { field: "movement_slip_links", header: "Movement" },
  ];

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180; // Convert degrees to radians
    const dLon = (lon2 - lon1) * Math.PI / 180; // Convert degrees to radians
    const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          error => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const attendanceFormSubmit = async () => {
    try {
      const location = await getGeoLocation();
      // Fixed coordinates to always compare against
      const fixedLat = 23.7644357;
      const fixedLng = 90.358958;

      const distance = calculateDistance(location.lat, location.lng, fixedLat, fixedLng);

      console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);

      const is_movement_slip = false;
      const dataToSend = { ...inputs, ...location,is_movement_slip, distance };
      const response = await Http.post("/attendance-app-save", dataToSend);
      navigate("/dashboard", {
        state: { message: response.data.message, type: "success" }
      });
    } catch (error) {
      if (error.response) {
        setError(error.response.data.errors);
      } else {
        console.error("Error obtaining location or sending data:", error);
      }
    }
  };

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-xs">
        {(authData.user_type === "admin" || authData.user_type === "hr") &&
        item.late == 1 &&
        item.salary == 0 ? (
          <button
            className="btn btn-info btn-sm ms-1"
            onClick={() => rejectLate(item.id, 1, "Approved")}
            title="Approve Late Attendance"
          >
            <i className="fa fa-check"></i>
          </button>
        ) : (
          ""
        )}
        {(authData.user_type === "admin" || authData.user_type === "hr") &&
        item.late == 0 &&
        item.salary == 0 &&
        item.reject_late_attendance == 1 ? (
          <button
            className="btn btn-danger btn-sm ms-1"
            title="Reject Late Attendance"
            onClick={() => rejectLate(item.id, 0, "Rejected")}
          >
            <i className="fa fa-minus"></i>
          </button>
        ) : (
          ""
        )}
        {authData.user_type === "admin" || authData.user_type === "hr" ? (
          item.approve_distance == 0 && item.salary == 0 ? (
            <button
              className="btn btn-primary btn-sm ms-1"
              onClick={() => approveDistance(item.id, 1)}
              title="Approve distance"
            >
              <i className="fa fa-check"></i>
            </button>
          ) : (
            <button
              className="btn btn-danger btn-sm ms-1"
              onClick={() => rejectDistance(item.id, 0)}
              title="Reject distance"
            >
              <i className="fa fa-minus"></i>
            </button>
          )
        ) : (
          ""
        )}
        {(authData.user_type === "admin" || authData.user_type === "hr") &&
        item.salary == 0 ? (
          <button
            className="btn btn-danger btn-sm ms-1"
            onClick={() => deleteData(item.id, "region", "Region")}
          >
            <i className="fa fa-trash"></i>
          </button>
        ) : (
          ""
        )}
      </td>
    );
  };

  return (
    <div>
      <IsLogin />
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Attendances</h5>
            {(authData.user_type === "admin" ||
              authData.user_type === "hr" ||
              authData.approved_by > 0) && (
              <div>
                <Link to="/import-attendance" className="btn btn-primary">
                  <i className="fa fa-upload mg-r-6"></i>Import Attendance
                </Link>
              </div>
            )}
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-sm-40">
              <div className="row">
                {(authData.user_type === "admin" ||
                  authData.user_type === "hr" ||
                  authData.approved_by > 0) && (
                  <div className="col-lg">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="hr_staff_basic_info_id"
                      options={staff}
                      onChange={selectHandleChange}
                    />
                  </div>
                )}
                <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="from"
                    value={inputs.from || ""}
                    onChange={handleChange}
                    placeholder="From Date"
                  />
                </div>
                <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="to"
                    value={inputs.to || ""}
                    onChange={handleChange}
                    placeholder="To Date"
                  />
                </div>
                <div className="col-lg">
                  <button
                    className="btn btn-primary btn-block mg-b-10"
                    onClick={formSubmit}
                  >
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
            {attendanceGiven && (
                <button 
                  className={`btn ${!attendanceGiven ? 'btn-primary' : 'btn-warning'} mg-b-10 float-right`} 
                  onClick={() => {
                    if (window.confirm("Are you sure you want to submit this action?")) {
                      attendanceFormSubmit();
                    }
                  }}
                >
                  <i className="fa fa-plus mg-r-10"></i>
                  {!attendanceGiven ? 'New Attendance' : 'Checkout'}
                </button>
              )}

              <ReactDataTable
                cols={cols}
                receiveData={formattedData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
