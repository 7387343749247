import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const PlanningList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(""); // State to hold the role

  

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/transfer-list");
      setPlanningData(res.data);

      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const cols = [
    { field: "receipt_no", header: "Receipt No" },
    { field: "transfer_date", header: "Transfer Date" },
    { field: "receive_date", header: "Receive Date" },
    { field: "created_by.name", header: "Created By" },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          {(
            item.status == 'delivered'?
            <>
              <Link to={`/receive-production-transfer/${item.id}`} className="btn btn-primary btn-sm">
                Receive
              </Link>
            </>
            :<i className="btn btn-success btn-sm">Received</i>
          )}
        </div>
      ),
      header: "Actions",
    },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Transfers</h5>
          <Link to="/add-new-transfer-art" className="btn btn-primary">
            <i className="fa fa-plus-square mg-r-6"></i> Add Transfer
          </Link>
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable cols={cols} receiveData={planningData} isLoading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningList;
