import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [rmpm, setRmpm] = useState([]);
  const [list, setList] = useState([]);
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const resRole = await Http.get("/staff-type-check");
        setRole(resRole.data);
        const resRmpm = await Http.get("/product-list/all");
        setRmpm(resRmpm.data);

        const resBatchNo = await Http.get(
          "/last-code-increment-by-one/requisition_arts/receipt_no/RD"
        );
        setInputs((values) => ({ ...values, ["receipt_no"]: resBatchNo.data }));

        getCurrentDateInput();
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["date"]: shortDate }));
    setInputs((values) => ({
      ...values,
      ["expected_production_date"]: shortDate,
    }));

    // Get the current time in the format "HH:mm"
    const hours = ("0" + dateObj.getHours()).slice(-2);
    const minutes = ("0" + dateObj.getMinutes()).slice(-2);

    const defaultTime = `${hours}:${minutes}`;

    setInputs((values) => ({ ...values, ["expected_time"]: defaultTime }));
  };

  const addMoreList = async (id) => {
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.log("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    }
  };
  // Remove List
  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  // Add handleInputChange function to update individual items in the list
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list]; // Copy the list array
    updatedList[index][name] = value; // Update the specific item in the list
    setList(updatedList); // Update the state with the modified list
  };

  const formSubmit = () => {
    checkRequiredFields();
    inputs.items = list;
    Http.post("/requisition-art", inputs)
      .then((res) => {
        navigate("/department-requisition-jcg", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response?.data?.errors || {});
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [
    {
      field: (i) => i.code + " || " + i.name,
      header: "Item",
    },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Department Requisition</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className={"card pd-20 pd-sm-40 col-lg-7"}>
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Create Department Requisition To{" "}
              <strong>
                {role === "jigger" || role === "casting" ? "Mould" : "Sagger"}
              </strong>
            </h6>
            <hr></hr>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />

                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Expected Time <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      name="expected_time"
                      value={inputs.expected_time || ""}
                      onChange={handleChange}
                      placeholder="Input Time"
                      data-required
                    />
                    {displayError("expected_time")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Req. No. <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="receipt_no"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Batch No"
                      data-required
                    />
                    {displayError("receipt_no")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the list</h5>
                    </div>
                    <div className="card-body mt-3 p-0 pt-3">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              #
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>

                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Request Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={3}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Requisition to{" "}
                                  {role === "jigger" || role === "casting"
                                    ? "Mould"
                                    : "Sagger"}
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                              <td> {list[i].name}</td>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="required_qty"
                                  value={x.required_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Req. Qty"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div
            className="card pd-20 pd-sm-40 col-4"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Materials
            </h6>
            <hr></hr>

            <ReactDataTable
              cols={cols}
              receiveData={rmpm}
              isLoading={loading}
              actionTemplate={actionTemplate}
              // deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
