import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import ReactDropdown from "../../../Common/ReactDropdown";


const Edit = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [staff, setStaff] = useState([]);
  const [error, setError] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const [res1, res2] = await Promise.all([Http.get(`/special-late-facility/${id}/edit`), Http.get("/staff")]);

        setInputs({
          hr_staff_basic_info_id: parseInt(res1.data.hr_staff_basic_info_id) ,
          year: res1.data.year,
        });

        setStaff(res2.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const fetchData = () => {
  //   Http.get("/special-late-facility/" + id + "/edit").then((res) => {
  //     setInputs({
  //       hr_staff_basic_info_id: res.data.hr_staff_basic_info_id,
  //       year: res.data.year,
  //     });
  //   });
  //   Http.get("/staff").then((res) => {
  //     setStaff(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.put("/special-late-facility/" + id, inputs)
        .then((res) => {
          navigate("/special-late-facility", {
            state: { message: res.data.message, type: res.data.type },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: prop ? e.value[prop] : e.value,
    }));
  };

const findSelectedOption = (options, value, key) => {
    return options.find((item) => item[key] === value);
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Special Late Facility Form</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Special Late Facility Form</h6>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">Staff</label>
                    {/* <select
                      className="form-control"
                      name="hr_staff_basic_info_id"
                      value={inputs.hr_staff_basic_info_id || ""}
                      onChange={handleChange}
                      data-required
                    >
                      <option value="">Select Staff</option>
                      {Array.from(staff).map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.card_no + " - " + item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown
                      options={staff}
                      optitle={"name"}
                      val={findSelectedOption(staff, inputs.hr_staff_basic_info_id, 'id')}
                      onChange={reactDropdownHandler("hr_staff_basic_info_id", "id")}
                    />
                  </div>
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Year <i className="fa fa-star required"></i>
                    </label>
                    <input type="text" className="form-control" name="year" value={inputs.year || ""} onChange={handleChange} data-required />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
