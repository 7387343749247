import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const CreateProductionTransfer = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [requisition, setRequisition] = useState([]);
  const [role, setRole] = useState("");
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);

  const [product, setProduct] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const lossTypes = ['BN', 'IS', 'GD'];
  const repairTypes = ['PH', 'SS'];

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);
  
  const fetchInitialData = async () => {
    setLoading(true);
    try {
      Http.get("/staff-type-check").then((res) => {
        setRole(res.data);
      });

      const resInvoiceNo = await Http.get("/last-code-increment-by-one/production_finished_goods/receipt_no/PS");
      setInputs((values) => ({
        ...values,
        receipt_no: resInvoiceNo.data,
      }));

      const resProduct = await Http.get("/fg-with-stock");
      setProduct(resProduct.data);
  
      const resRmpm = await Http.get("/rmpm-with-stock");
      const combined = [
        ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
        ...resProduct.data.map((item) => ({ ...item, type: "FG" })),
      ];
      setCombinedData(combined);
  
      const resWorkOrders = await Http.get("/production-work-order");
      setWorkOrders(resWorkOrders.data);
      const resRequisition = await Http.get("/requisition-art");
      setRequisition(resRequisition.data);
  
      const response = await Http.get("/shape");
      setShapes(response.data);
  
      const uniqueShapeTypes = [
        ...new Map(response.data.map((item) => [item.shape_type.id, item.shape_type])).values(),
      ];
      setShapeTypes(uniqueShapeTypes);
  
      // Fetch staff type and auto-select the from_department
      const responseStaff = await Http.get("/staff-type-check");
      const staffType = responseStaff.data;
  
      const matchingDepartment = departments.find(
        (dept) => dept.user_type === staffType
      );
  
      if (matchingDepartment) {
        // Pre-populate `from_department` for existing items
        setList((prevList) =>
          prevList.map((item) => ({
            ...item,
            from_department: matchingDepartment.id,
          }))
        );
  
        // Also set a default from_department for future items
        setInputs((prevInputs) => ({
          ...prevInputs,
          from_department: matchingDepartment.id,
        }));
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        const newItem = {
          ...response.data,
          from_department: inputs.from_department || "", // Assign the default `from_department`
        };
        setList((prev) => [...prev, newItem]);
      } else {
        Swal.fire("Warning!", "This Product is already in the list!", "warning");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = !isNaN(value) && value !== "" ? parseInt(value) : value;
    setInputs((values) => ({ ...values, [name]: parsedValue }));
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];
  //   updatedList[index][name] = value;

  //   // Conditional logic for 'to_department' dropdown
  //   if (name === "from_department") {
  //     let allowedDepartments = [];

  //     switch (value) {
  //       case "1": // BODY
  //         allowedDepartments = [2, 3, 6]; // JIGGER, CASTING, GLAZING
  //         break;
  //       case "2": // JIGGER
  //         allowedDepartments = [4]; // FINISHING
  //         break;
  //       case "3": // CASTING
  //         allowedDepartments = [5]; // BK
  //         break;
  //       default:
  //         allowedDepartments = [7];
  //     }

  //     // Automatically set 'to_department' if there's only one option
  //     updatedList[index].to_department = 
  //       allowedDepartments.length === 1 ? allowedDepartments[0] : "";
  //   }

  //   setList(updatedList);
  // };

  // const getToDepartmentOptions = (fromDepartment) => {
  //   switch (fromDepartment) {
  //     case 1: // BODY
  //       return [2, 3, 6]; // JIGGER, CASTING, GLAZING
  //     case 2: // JIGGER
  //       return [4]; // FINISHING
  //     case 3: // CASTING
  //       return [5]; // BK
  //     default:
  //       return [];
  //   }
  // };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    updatedList[index][name] = value;

    if (name === "from_department") {
      let allowedDepartments = getToDepartmentOptions(parseInt(value));

      updatedList[index].to_department =
        allowedDepartments.length === 1 ? allowedDepartments[0] : "";
    }

    setList(updatedList);
  };

  const getToDepartmentOptions = (fromDepartment) => {
    switch (fromDepartment) {
      case 1: // BODY
        return [2, 3, 6]; // JIGGER, CASTING, GLAZING
      case 2: // JIGGER
        return [4]; // FINISHING
      case 3: // CASTING
        return [5]; // BK
      case 4: // FINISHING
        return [5]; // BK
      case 5: // BK
        return [6]; // GLAZING
      case 6: // GLAZING
        return [7]; // GK
      case 7: // GK
        return [8]; // SELECTION
      case 8: // SELECTION
        return [9, 10]; // DECORATION LOCAL, DECORATION EXPORT
      case 9: // DECORATION LOCAL
      case 10: // DECORATION EXPORT
        return [11]; // DFW
      case 11: // DFW
        return [12, 13]; // PACKING LOCAL, PACKING EXPORT
      default:
        return [];
    }
  };
  
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const inputInfo = [...list];
  //   inputInfo[index][name] = value;
  //   setList(inputInfo);
  // };
  
  const formSubmit = () => {
    inputs.items = list.map((item) => ({
      ...item,
      product_id: item.id,
      shape_id: item.shape_id || null,
      shape_type_id: item.shape_type_id || null,
      delivery_qty: item.qty || 0,
      receive_qty: item.receive_qty || 0,
      qty: item.qty || 0,
      loss_qty: item.loss_qty || 0,
      loss_type: item.loss_type || null,
      repair_qty: item.repair_qty || 0,
      repair_type: item.repair_type || null,
      from_department: item.from_department || inputs.from_department, // Ensure from_department is set
      to_department: item.to_department || "",
      decoration: item.decoration || "",
      remarks: item.remarks || "",
      plaster_of_paris_consumption_per_pc_in_kg: item.plaster_of_paris_consumption_per_pc_in_kg || 0,
      plaster_of_paris_consumption_total_in_kg: item.plaster_of_paris_consumption_total_in_kg || 0,
    }));
  
    Http.post("/production-transfer", inputs)
      .then((res) => {
        navigate("/production-transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const cols = [{ field: "name", header: "Item" }];

  const actionTemplate = (item) => (
    <td className="text-right">
      <button className="btn btn-success btn-sm" onClick={() => addMoreList(item.id)}>
        <i className="fa fa-plus"></i>
      </button>
    </td>
  );

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production Form</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">Production Form</h6>
            <hr />
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>Receipt No <i className="fa fa-star required"></i></label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>Requisition<i className="fa fa-star required"></i></label>
                    <select
                      className="form-control"
                      name="requisition_id"
                      value={inputs.requisition_id || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Requisition</option>
                      {requisition.map((order) => (
                        <option key={order.id} value={order.id}>
                          {order.receipt_no}
                        </option>
                      ))}
                    </select>
                    {displayError("requisition_id")}
                  </div>
                  <div className="col-lg">
                    <label>Shift<i className="fa fa-star required"></i></label>
                    <select
                      className="form-control"
                      name="shift"
                      value={inputs.shift || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Shifting</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="d">D</option>
                    </select>
                    {displayError("requisition_id")}
                  </div>
                  {/* <div className="col-lg">
                    <label>Work Order <i className="fa fa-star required"></i></label>
                    <select
                      className="form-control"
                      name="production_work_order_id"
                      value={inputs.production_work_order_id || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Work Order</option>
                      {workOrders.map((order) => (
                        <option key={order.id} value={order.id}>
                          {order.receipt_no}
                        </option>
                      ))}
                    </select>
                    {displayError("production_work_order_id")}
                  </div> */}
                  {/* <div className="col-lg">
                    <label>Production Date <i className="fa fa-star required"></i></label>
                    <input
                      type="date"
                      className="form-control"
                      name="production_date"
                      value={inputs.production_date || ""}
                      onChange={handleChange}
                    />
                    {displayError("production_date")}
                  </div>
                  <div className="col-lg">
                    <label>Transfer Date <i className="fa fa-star required"></i></label>
                    <input
                      type="date"
                      className="form-control"
                      name="transfer_date"
                      value={inputs.transfer_date || ""}
                      onChange={handleChange}
                    />
                    {displayError("transfer_date")}
                  </div> */}
                  {/* <div className="col-lg">
                    <label>Receive Date <i className="fa fa-star required"></i></label>
                    <input
                      type="date"
                      className="form-control"
                      name="receive_date"
                      value={inputs.receive_date || ""}
                      onChange={handleChange}
                    />
                    {displayError("receive_date")}
                  </div> */}
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the Production</h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            {/* <th>Shape</th>
                            <th>Shape Type</th> */}
                            <th>Production Qty</th>
                            {/* <th>Delivery Qty</th> */}
                            {/* <th>Receive Qty</th> */}
                            {(role != "body") && (
                              <>
                                <th>Loss Type</th>
                                <th>Loss Qty</th>
                              </>
                            )}
                            {
                            (role == "selection" || role == 'packing_local' || role == 'packing_export') && (
                              <>
                                <th>Repair Qty</th>
                                <th>Repair Type</th>
                              </>
                            )}
                            
                            {/* <th>From Department</th> */}
                            <th>To Department</th>
                            {(
                            role == "decoration_local" || role == 'decoration_export' && (
                              <>
                                <th>Decoration</th>
                              </>
                            )
                            )}
                            <th>Transfer Qty</th>
                            {/* <th>Plaster Consumption per pc (kg)</th>
                            <th>Total Plaster Consumption (kg)</th>
                            <th>Remarks</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={17} className="text-center">Please add products</td>
                            </tr>
                          ) : (
                            list.map((x, i) => (
                              <tr key={i}>
                                <td>
                                  <button className="btn btn-danger btn-sm" onClick={() => removeList(i)}>
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                <td>{x.name}</td>
                                {/* <td>
                                  <select
                                    className="form-control"
                                    name="shape_id"
                                    value={x.shape_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape</option>
                                    {shapes.map((shape) => (
                                      <option key={shape.id} value={shape.id}>
                                        {shape.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_type_id"
                                    value={x.shape_type_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape Type</option>
                                    {shapeTypes.map((type) => (
                                      <option key={type.id} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </select>
                                </td> */}
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="qty"
                                    value={x.qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Required Qty"
                                  />
                                </td>
                                {/* <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="delivery_qty"
                                    value={x.delivery_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Delivery Qty"
                                  />
                                </td> */}
                                {/* <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="receive_qty"
                                    value={x.receive_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Receive Qty"
                                  />
                                </td> */}
                                {(role != "body") && (
                                  <>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="loss_type"
                                        value={x.loss_type || ""}
                                        onChange={(e) => handleInputChange(e, i)}
                                      >
                                        <option value="">Select Loss Type</option>
                                        {lossTypes.map((type) => (
                                          <option key={type} value={type}>
                                            {type}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="loss_qty"
                                        value={x.loss_qty || ""}
                                        onChange={(e) => handleInputChange(e, i)}
                                        placeholder="Loss Qty"
                                      />
                                    </td>
                                  </>
                                )}
                                {(role == "selection" || role == 'packing_local' || role == 'packing_export') && (
                                  <>
                                    <td> 
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="repair_qty"
                                        value={x.repair_qty || ""}
                                        onChange={(e) => handleInputChange(e, i)}
                                        placeholder="Repair Qty"
                                      />
                                    </td>
                                    <td>
                                      <select
                                        className="form-control"
                                        name="repair_type"
                                        value={x.repair_type || ""}
                                        onChange={(e) => handleInputChange(e, i)}
                                      >
                                        <option value="">Select Repair Type</option>
                                        {repairTypes.map((type) => (
                                          <option key={type} value={type}>
                                            {type}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    </>
                                )}
                                {/* <td>
                                  <select
                                    className="form-control"
                                    name="from_department"
                                    value={x.from_department || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    disabled
                                  >
                                    <option value="">Select From Department</option>
                                    {departments.map((dept) => (
                                      <option key={dept.id} value={dept.id}>
                                        {dept.name}
                                      </option>
                                    ))}
                                  </select>
                                </td> */}
                                {/* <td>
                                  <select
                                    className="form-control"
                                    name="to_department"
                                    value={x.to_department || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select To Department</option>
                                    {departments.map((dept) => (
                                      <option key={dept.id} value={dept.id}>
                                        {dept.name}
                                      </option>
                                    ))}
                                  </select>
                                </td> */}
                                <td>
                                <select
                                  className="form-control"
                                  name="to_department"
                                  value={x.to_department || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  disabled={getToDepartmentOptions(x.from_department).length === 1}
                                >
                                  <option value="">Select To Department</option>
                                  {getToDepartmentOptions(x.from_department).map((deptId) => (
                                    <option key={deptId} value={deptId}>
                                      {departments.find((dept) => dept.id === deptId)?.name || ""}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              {(
                                role == "decoration_local" || role == 'decoration_export' && (
                                  <>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="decoration"
                                        value={x.decoration || ""}
                                        onChange={(e) => handleInputChange(e, i)}
                                        placeholder="Decoration"
                                      />
                                    </td>
                                  </>
                                )
                              )}
                                
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="transfer_qty"
                                    value={x.transfer_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Transfer Qty"
                                  />
                                </td>
                                {/* <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="plaster_of_paris_consumption_per_pc_in_kg"
                                    value={x.plaster_of_paris_consumption_per_pc_in_kg || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Consumption per pc (kg)"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="plaster_of_paris_consumption_total_in_kg"
                                    value={x.plaster_of_paris_consumption_total_in_kg || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Total Consumption (kg)"
                                  />
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    name="remarks"
                                    value={x.remarks || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Remarks"
                                  />
                                </td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Create Transfer
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card pd-20 pd-sm-40 col-3" style={{ backgroundColor: "#ffffffc7" }}>
            <h6 className="card-body-title mg-sm-b-30 text-center">Available Items</h6>
            <hr />
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProductionTransfer;
