import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Http from "../../Common/Http";

const Workplace = ({ onMenuClick, active }) => {
  const [role, setRole] = useState(""); // State to hold the role

  // Fetch the role from /staff-check
  useEffect(() => {
    const fetchRole = async () => {
      try {
        Http.get("/staff-type-check").then((res) => {
          setRole(res.data);
        });
        // const response = await axios.get("/staff-type-check");
        // setRole(response.data.role); // Set role based on response
      } catch (error) {
        console.error("Error fetching role:", error);
      }
    };

    fetchRole();
  }, []);

  return (
    <>
      <li className="nav-item">
        <Link
          to
          className={`nav-link with-sub ${
            active === "/machine" ||
            active === "/shape-type" ||
            active === "/shape" ||
            active === "/planning" ||
            active === "/production-art" ||
            active === "/company" ||
            // active === "/production" ||
            active === "/grn" ||
            active === "/production-transfer-art" ||
            // active === "/requisition-production" ||
            // active === "/issue" ||
            active === "/transfer-art" ||
            // active === "/factory-transfer" ||
            active === "/factory-return" ||
            active === "/store-purchase-requisition-art" ||
            active === "/production-work-order"
              ? "active show-sub"
              : ""
          }`}
        >
          <i className="fa fa-briefcase" aria-hidden="true"></i>
          <span>Artisan</span>
        </Link>
        <ul className="nav-sub">
          {/* Admin and Staff (Machine access) */}
          {(role === "admin" || role === "staff") && (
            <li className="nav-item">
              <Link
                to="/machine"
                className={`nav-link ${active === "/machine" ? "active" : ""}`}
                onClick={() => onMenuClick("/machine")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Machine
              </Link>
            </li>
          )}

          {/* Admin and Staff (Shape Type access) */}
          {/* {(role === "admin" || role === "staff") && (
            <li className="nav-item">
              <Link
                to="/shape-type"
                className={`nav-link ${
                  active === "/shape-type" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/shape-type")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Shape Type
              </Link>
            </li>
          )} */}

          {/* Admin and Staff (Shape access) */}
          {(role === "admin" || role === "staff") && (
            <li className="nav-item">
              <Link
                to="/shape"
                className={`nav-link ${active === "/shape" ? "active" : ""}`}
                onClick={() => onMenuClick("/shape")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Shape
              </Link>
            </li>
          )}

          {/* Admin and Staff (Production Work Order access) */}
          {(role === "admin" || role === "staff" || role === "planning") && (
            <li className="nav-item">
              <Link
                to="/production-work-order"
                className={`nav-link ${
                  active === "/production-work-order" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/production-work-order")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Work Order
              </Link>
            </li>
          )}

          {/* Admin and Staff (Company access) */}
          {(role === "admin" || role === "staff") && (
            <li className="nav-item">
              <Link
                to="/company"
                className={`nav-link ${active === "/company" ? "active" : ""}`}
                onClick={() => onMenuClick("/company")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Company
              </Link>
            </li>
          )}

          {/* Everyone (Planning access) */}
          {(role === "admin" ||
            role === "planning" ||
            role === "casting" ||
            role === "jigger" ||
            role === "packing_local" ||
            role === "packing_export" ||
            role === "decoration_local" ||
            role === "decoration_export") && (
            <li className="nav-item">
              <Link
                to="/planning"
                className={`nav-link ${active === "/planning" ? "active" : ""}`}
                onClick={() => onMenuClick("/planning")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Work Plan
              </Link>
            </li>
          )}

          {(role === "admin" ||
            role === "gstore" ||
            role === "dstore" ||
            role === "pstore") && (
            <li className="nav-item">
              <Link
                to="/store-purchase-requisition-art"
                className={`nav-link ${
                  active === "/store-purchase-requisition-art" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/store-purchase-requisition-art")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; SPR
                <span className="badge bg-danger ms-2">33</span>
              </Link>
            </li>
          )}
          {(role === "admin" ||
            role === "depot" ||
            role === "dstore" ||
            role === "gstore" ||
            role === "pstore") && (
            <li className="nav-item">
              <Link
                to="/direct-purchase"
                className={`nav-link ${
                  active === "/direct-purchase" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/direct-purchase")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Direct Purchase
              </Link>
            </li>
          )}
          {(role === "admin" ||
            // role === "body" ||
            role === "gstore" ||
            role === "dstore" ||
            role === "pstore") && (
            // role === "mould" ||
            // role === "sagger"
            // role === "decoration_local" ||
            // role === "decoration_export" ||
            // role === "packing_local" ||
            // role === "packing_export"
            <li className="nav-item">
              <Link
                to="/grn"
                className={`nav-link ${active === "/grn" ? "active" : ""}`}
                onClick={() => onMenuClick("/grn")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; GRN
              </Link>
            </li>
          )}
          {(role === "admin" ||
            role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "bk" ||
            role === "glazing" ||
            role === "gk" ||
            role === "selection" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "dfw" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/production-art"
                className={`nav-link ${
                  active === "/production-art" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/production-art")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Production and Transfer
              </Link>
            </li>
          )}

          {/* Specific roles for Requisition access */}
          {/* {(role === "admin" ||
            role === "body" ||
            role === "gstore" ||
            role === "dstore" ||
            role === "pstore" ||
            role === "mould" ||
            role === "sagger" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/requisition-production"
                className={`nav-link ${
                  active === "/requisition-production" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/requisition-production")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Store Requisition
              </Link>
            </li>
          )} */}

          {/* {(role === "admin" ||
            role === "body" ||
            role === "gstore" ||
            // role === "dstore" ||
            // role === "pstore" ||
            role === "mould" ||
            role === "sagger" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/issue"
                className={`nav-link ${active === "/issue" ? "active" : ""}`}
                onClick={() => onMenuClick("/issue")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Issue
              </Link>
            </li>
          )} */}
          {/* {(role === "admin" ||
            role === "body" ||
            // role === "gstore" ||
            role === "dstore" ||
            role === "pstore" ||
            role === "mould" ||
            role === "sagger") && (
            // role === "decoration_local" ||
            // role === "decoration_export" ||
            // role === "packing_local" ||
            // role === "packing_export"
            <li className="nav-item">
              <Link
                to="/production"
                className={`nav-link ${
                  active === "/production" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/production")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Production
              </Link>
            </li>
          )} */}

          {/* {(role === "admin" ||
            role === "body" ||
            role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "bk" ||
            role === "glazing" ||
            role === "gk" ||
            role === "selection" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "dfw" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/production-transfer-art"
                className={`nav-link ${
                  active === "/production-transfer-art" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/production-transfer-art")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Production and Transfer
              </Link>
            </li>
          )} */}
          {/* {(role === "admin" ||
            // role === "body" ||
            role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "bk" ||
            role === "glazing" ||
            role === "gk" ||
            role === "selection" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "dfw" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/transfer-art"
                className={`nav-link ${
                  active === "/transfer-art" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/transfer-art")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Transfer
              </Link>
            </li>
          )} */}
          {/* {(role === "admin" ||
            role === "body" ||
            role === "jigger" ||
            role === "casting" ||
            role === "finishing" ||
            role === "bk" ||
            role === "glazing" ||
            role === "gk" ||
            role === "selection" ||
            role === "decoration_local" ||
            role === "decoration_export" ||
            role === "dfw" ||
            role === "packing_local" ||
            role === "packing_export") && (
            <li className="nav-item">
              <Link
                to="/factory-transfer"
                className={`nav-link ${
                  active === "/factory-transfer" ? "active" : ""
                }`}
                onClick={() => onMenuClick("/factory-transfer")}
              >
                <i className="fa fa-clone" aria-hidden="true"></i>
                &nbsp;&nbsp; Department Transfer
              </Link>
            </li>
          )} */}
          
        </ul>
      </li>
    </>
  );
};

export default Workplace;
