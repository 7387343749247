import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
// import { LoaderComponent } from "../../../Common/LoaderComponent";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const List = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const res = await Http.get(`/requisition-art`);
      const resRole = await Http.get(`/staff-type-check`);
      setReceiveData(res.data);
      setRole(resRole.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };
  const cols = [
    { field: "receipt_no", header: "Req. No." },
    { field: "date", header: "Date" },
    { field: "expected_time", header: "Time" },
    // { field: "product.name", header: "Finished Good" },
    // { field: "batch_size", header: "Batch Size" },
    ...(role === "admin"
      ? [{ field: "from_department.name", header: "From" }]
      : []), // Conditionally add the "From" column
    { field: "to_department.name", header: "To" },
    { field: "created_by.name", header: "Created by" },
  ];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-xs">
        <Link
          to={{
            pathname: "/show-department-requisition-jcg/" + item.id,
          }}
          className="btn btn-info btn-sm"
        >
          <i className="fa fa-list" aria-hidden="true"></i>
        </Link>
        {/* {item.issue === null &&
        (role === "gstore" || role === "pstore" || role === "dstore") ? (
          <Link
            to={{
              pathname: "/create-issue/" + item.id,
            }}
            className="btn btn-primary btn-sm ms-1"
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </Link>
        ) : (
          ""
        )} */}
        {(role === "mould" || role === "sagger") &&
        item.requisition_production == null ? (
          <Link
            to={{
              pathname: "/create-requisition-production/" + item.id,
            }}
            className="btn btn-primary btn-sm ms-1"
          >
            <i className="fa fa-plus-square" aria-hidden="true"></i>
          </Link>
        ) : (
          ""
        )}
        {(role === "mould" || role === "sagger") &&
        item.requisition_production !== null ? (
          <Link
            to={{
              pathname:
                "/requisition-production-view/" +
                item.requisition_production.id,
            }}
            className="btn btn-warning btn-sm ms-1"
          >
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </Link>
        ) : (
          ""
        )}

        {/* Check if role and created_by's user_type match */}
        {item.issue == null && item.created_by?.user_type === role ? (
          <>
            <Link
              to={{
                pathname: "/edit-requisition-production/" + item.id,
              }}
              className="btn btn-secondary btn-sm ms-1"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </Link>

            <button
              className="btn btn-danger btn-sm ms-1"
              onClick={() =>
                deleteData(
                  item.id,
                  "requisition-production",
                  "Requisition Production"
                )
              }
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        ) : (
          ""
        )}
      </td>
    );
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Departmental Requisition</h5>

            {role !== "mould" && role !== "sagger" ? (
              <Link
                to="/create-department-requisition-jcg"
                className="btn btn-primary"
              >
                <i className="fa fa-plus-square mg-r-6"></i> Add Requisition
              </Link>
            ) : (
              ""
            )}
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <ReactDataTable
                cols={cols}
                receiveData={receiveData}
                isLoading={loading}
                actionTemplate={actionTemplate}
                deleteData={deleteData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
