import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const ProductionWorkOrderList = () => {
  const [planningData, setPlanningData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);

  // Fetch data from /planning API
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/production-work-order");
      setPlanningData(res.data);
      const res1 = await Http.get("/staff-type-check");
      setRole(res1.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Use custom hook to delete data
  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  const cols = [
    { field: "receipt_no", header: "Work Order" },
    { field: "company.name", header: "Company" },
    { field: "work_order_date", header: "Work Order Date" },
    { field: "delivery_date", header: "Delivery Date" },
    { field: "created_by.name", header: "Created By" },
    // { field: "status", header: "Status" },
    {
      field: (item) => (
        <div className="text-xs text-nowrap">
          <Link to={`/show-production-work-order/${item.id}`} className="btn btn-info btn-sm">
            <i className="fa fa-list" aria-hidden="true"></i>
          </Link>
          {(role === "admin") && item.planning == null && (
            <>
              {/* <Link to={`/create-planning-work-order-wise/${item.id}`} className="btn btn-success btn-sm ms-1">
                <i className="fa fa-plus" aria-hidden="true"></i>
              </Link> */}
              <Link to={`/edit-production-work-order/${item.id}`} className="btn btn-primary btn-sm ms-1">
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </Link>
            </>
          )}
          {/* <button className="btn btn-danger btn-sm ms-1" onClick={() => deleteData(item.id, "planning", "planning")} title="Delete">
            <i className="fa fa-trash"></i> Delete
          </button> */}
        </div>
      ),
      header: "Actions",
    },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">All Work Order</h5>
          {
            (role === "admin") && (
              <Link to="/create-production-work-order" className="btn btn-primary">
                <i className="fa fa-plus"></i> Create Work Order
              </Link>
            )
          }
        </div>
        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card pd-20 pd-sm-40">
            <ReactDataTable cols={cols} receiveData={planningData} isLoading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionWorkOrderList;
