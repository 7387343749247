import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDataTable from "../../../Common/ReactDataTable";

const EditPlanning = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [machines, setMachines] = useState([]); // Store machines from API
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // Fetch existing planning data
      const resPlanning = await Http.get(`/planning/${id}`);
      const planningData = resPlanning.data;

      setInputs({
        receipt_no: planningData.receipt_no || "",
        date: planningData.date || "",
        delivery_date: planningData.delivery_date || "",
        remarks: planningData.remarks || "",
      });
      setList(planningData.items);

      // Fetch additional data for products, shapes, shape types, and machines
      const resProduct = await Http.get("/fg-with-stock");
        setProduct(resProduct.data);

        const resRmpm = await Http.get("/rmpm-with-stock");
        setRmpm(resRmpm.data);

        const combined = [
          ...resRmpm.data.map(item => ({ ...item, type: 'RMPM' })),
          ...resProduct.data.map(item => ({ ...item, type: 'FG' }))
        ];
      setCombinedData(combined);
      const resShape = await Http.get("/shape");
      setShapes(resShape.data);
      const resShapeType = await Http.get("/shapetype");
      setShapeTypes(resShapeType.data);
      const resMachine = await Http.get("/machine");
      setMachines(resMachine.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true); // Use itemLoading to control this specific action
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire("Warning!", "This Product is already in the list!", "warning");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false); // Stop loading after action is complete
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list;
    // Prepare updated list of items
    const updatedList = list.map((item) => ({
      ...item,
      product_id: item.product_id, // Ensure product_id is included
      shape_id: item.shape_id || null, // Ensure shape_id is included
      machine_id: item.machine_id || null, // Ensure machine_id is included
      shape_type_id: item.shape_type_id || null, // Ensure shape_type_id is included
      starting_date: item.starting_date || null, // Ensure starting date is included
      closing_date: item.closing_date || null, // Ensure closing date is included
      total_day: item.total_day || null, // Ensure total_day is included
      target_day_wise: item.target_day_wise || null, // Ensure target_day_wise is included
    }));

    // Assign the updated list to inputs.items
    inputs.items = updatedList;

    // Make the PUT request to update planning
    Http.put(`/planning/${id}`, inputs)
      .then((res) => {
        navigate("/planning", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((e) => {
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const cols = [{ field: "name", header: "Item" }];
  const actionTemplate = (item) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Planning</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Edit Planning Form
            </h6>
            <hr />
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />
                    {displayError("date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Delivery Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="delivery_date"
                      value={inputs.delivery_date || ""}
                      onChange={handleChange}
                      placeholder="Input Delivery Date"
                    />
                    {displayError("delivery_date")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Remarks <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />
                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Planning{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Shift</th>
                            <th>Product</th>
                            {/* <th>Machine</th> */}
                            <th>Shape</th>
                            <th>Shape Type</th>
                            <th>Starting</th>
                            <th>Closing</th>
                            <th>Total Day</th>
                            <th>Target Day wise</th>
                            <th>Total Target Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={11}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Planning
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="shift"
                                  value={x.shift || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shift</option>
                                  <option value="a">A</option>
                                  <option value="b">B</option>
                                  <option value="c">C</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={x.product?.name || ""}
                                  disabled
                                />
                              </td>
                              {/* <td>
                                <select
                                  className="form-control"
                                  name="machine_id"
                                  value={x.machine_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Machine</option>
                                  {machines.map((machine) => (
                                    <option key={machine.id} value={machine.id}>
                                      {machine.name}
                                    </option>
                                  ))}
                                </select>
                              </td> */}
                              <td>
                                <select
                                  className="form-control"
                                  name="shape_id"
                                  value={x.shape_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape</option>
                                  {shapes.map((shape) => (
                                    <option key={shape.id} value={shape.id}>
                                      {shape.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name="shape_type_id"
                                  value={x.shape_type_id || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                >
                                  <option value="">Select Shape Type</option>
                                  {shapeTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="starting_date"
                                  value={x.starting_date || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="closing_date"
                                  value={x.closing_date || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="total_day"
                                  value={x.total_day || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="target_day_wise"
                                  value={x.target_day_wise || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="total_target_qty"
                                  value={x.total_target_qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr />
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlanning;
