import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";

import useAlertFromLocation from "../../../../hooks/useAlertFromLocation";
import useDeleteData from "../../../../hooks/useDeleteData";
import ReactDataTable from "../../../Common/ReactDataTable";

const ListShape = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await Http.get("/shape"); // Ensure this matches your API route
      setReceiveData(res.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteData = useDeleteData(fetchData);
  const alert = useAlertFromLocation();

  // Update column names based on the shape model
  const cols = [
    { field: "name", header: "Name" },
    { field: "code", header: "Code" },
    { field: "status", header: "Status" },
  ];

  return (
    <div>
      <IsLogin />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Shapes</h5>
            <Link to="/create-shape" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i> Shape
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-20 pd-sm-40">
              <div className="row">
                <ReactDataTable
                  cols={cols}
                  receiveData={receiveData}
                  isLoading={loading}
                  actionTemplate={actionTemplate}
                  deleteData={deleteData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/edit-shape/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
      >
        <i className="fa fa-pencil"></i>
      </Link>
      <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(rowData.id, "deleteShape", "Shape")} // Ensure delete route matches your API
      >
        <i className="fa fa-trash"></i>
      </button>
    </div>
  );
};

export default ListShape;
