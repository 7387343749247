import $ from "jquery";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resShapes = await Http.get("/shape");
      setShapes(resShapes.data);

      const resWorkOrders = await Http.get("/production-work-order");
      setWorkOrders(resWorkOrders.data);

      const resProduct = await Http.get("/fg-with-stock");
      setProduct(resProduct.data);

      const resRmpm = await Http.get("/rmpm-with-stock");
      setRmpm(resRmpm.data);

      const combined = [
        ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
        ...resProduct.data.map((item) => ({ ...item, type: "FG" })),
      ];
      setCombinedData(combined);

      const uniqueShapeTypes = [
        ...new Map(
          resShapes.data.map((item) => [item.shape_type.id, item.shape_type])
        ).values(),
      ];
      setShapeTypes(uniqueShapeTypes);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];
    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list.map((item) => ({
      ...item,
      product_id: item.id,
      shape_id: item.shape_id || null,
      shape_type_id: item.shape_type_id || null,
    }));

    Http.post("/production-return", inputs)
      .then((res) => {
        navigate("/production-return", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production Return Form</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Production Return
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Work Order <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="production_work_order_id"
                      value={inputs.production_work_order_id || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Work Order</option>
                      {workOrders.map((order) => (
                        <option key={order.id} value={order.id}>
                          {order.receipt_no}
                        </option>
                      ))}
                    </select>
                    {displayError("production_work_order_id")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Date"
                    />
                    {displayError("date")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      From Department <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="from_department"
                      value={inputs.from_department || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("from_department")}
                  </div>
                  <div className="col-lg">
                    <label>
                      To Department <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="to_department"
                      value={inputs.to_department || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {displayError("to_department")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Shift <i className="fa fa-star required"></i>
                    </label>
                    <select
                      className="form-control"
                      name="shift"
                      value={inputs.shift || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Shift</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="d">D</option>
                    </select>
                    {displayError("shift")}
                  </div>
                  <div className="col-lg">
                    <label>Remarks</label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />
                    {displayError("remarks")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Shape</th>
                            <th>Shape Type</th>
                            <th>Return Qty</th>
                            {/* <th>Receive Qty</th> */}
                            <th>Decoration</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Please add some products.
                              </td>
                            </tr>
                          ) : (
                            list.map((x, i) => (
                              <tr key={i}>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeList(i)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                <td>{x.name}</td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_id"
                                    value={x.shape_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape</option>
                                    {shapes.map((shape) => (
                                      <option key={shape.id} value={shape.id}>
                                        {shape.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_type_id"
                                    value={x.shape_type_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape Type</option>
                                    {shapeTypes.map((type) => (
                                      <option key={type.id} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="return_qty"
                                    value={x.return_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Return Qty"
                                  />
                                </td>
                                {/* <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="receive_qty"
                                    value={x.receive_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Receive Qty"
                                  />
                                </td> */}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="decoration"
                                    value={x.decoration || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Decoration"
                                  />
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    name="remarks"
                                    value={x.remarks || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Remarks"
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Products
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={[{ field: "name", header: "Item" }]}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={(item) => (
                <td className="text-right">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => addMoreList(item.id)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </td>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
