import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDropdown from "../../../Common/ReactDropdown";


const CreateRegion = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});
  const [product, setProduct] = useState({});
  const [unit, setUnit] = useState({});
  const [error, setError] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [vendor, setVendor] = useState({});
  const [list, setList] = useState([
    { asset_id: "", unit_id: "", qty: "", desc: "" },
  ]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const resVendor = await Http.get("/vendor");
        setVendor(resVendor.data);

        const resLocation = await Http.get("/location");
        setLocation(resLocation.data);

        const resFactory = await Http.get("/factory");
        setFactory(resFactory.data);

        const resDepartment = await Http.get("/department");
        setDepartment(resDepartment.data);

        const resAsset = await Http.get("/asset");
        setProduct(resAsset.data);

        const resUnit = await Http.get("/unit");
        setUnit(resUnit.data);

        const resCodeIncrement = await Http.get(
          "/last-code-increment-by-one/asset_direct_purchases/receipt_no/ADP"
        );
        setInputs((values) => ({
          ...values,
          receipt_no: resCodeIncrement.data,
        }));
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    getCurrentDateInput();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleFileChange = (e) => {
    const fileArray = [];
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      fileArray.push(e.target.files[key]);
    });
    setSelectedFile(fileArray);
    setIsSelected(true);
  };

  // Set Experience State
  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["date"]: shortDate }));
  };
  // Default Load
  // const fetchData = () => {
  //   Http.get("/vendor").then((res) => {
  //     setVendor(res.data);
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/department").then((res) => {
  //     setDepartment(res.data);
  //   });
  //   Http.get("/asset").then((res) => {
  //     setProduct(res.data);
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  //   Http.get("/last-code-increment-by-one/asset_direct_purchases/receipt_no/ADP").then((res) => {
  //     setInputs((values) => ({ ...values, ["receipt_no"]: res.data }));
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  //   getCurrentDateInput();
  // }, []);
  // End Default Load

  // Add More List
  const addMoreList = () => {
    setList([
      ...list,
      { asset_id: "", unit_id: "", qty: "", price: "", desc: "" },
    ]); // Add One More List
  };
  // Remove List
  const removeList = (index) => {
    const inputInfo = [...list]; // Load All Existing experience State Data
    inputInfo.splice(index, 1); // Remove Experience by Index/Click Event Wise
    setList(inputInfo); // Replace Existing experience into State
  };

  const formSubmit = () => {
    const listArr = [];
    Object.keys(list).forEach((key) => {
      listArr.push(list[key]);
    });

    // inputs.file = selectedFile;
    inputs.items = listArr;
    Http.post("/asset-direct-purchase", inputs)
      .then((res) => {
        navigate("/asset-direct-purchase", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: e.value[prop],
    }));
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Direct Purchase</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Direct Purchase</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Receipt no <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">
                      Vendor <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="vendor_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Vendor</option>
                      {Array.from(vendor).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={vendor} optitle={"name"} onChange={reactDropdownHandler("vendor_id", "id")} />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Location</label>
                    {/* <select
                      className="form-control"
                      name="company_location_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Location</option>
                      {Array.from(location).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={location} optitle={"name"} onChange={reactDropdownHandler("company_location_id", "id")} />

                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label htmlFor="">Factory</label>
                    {/* <select
                      className="form-control"
                      name="factory_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Factory</option>
                      {Array.from(factory).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={factory} optitle={"name"} onChange={reactDropdownHandler("factory_id", "id")} />

                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Department</label>
                    {/* <select
                      className="form-control"
                      name="department_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {Array.from(department).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={department} optitle={"name"} onChange={reactDropdownHandler("department_id", "id")} />

                  </div>
                  <div className="col-lg">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={inputs.description || ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <button
                      className="btn btn-info btn-block mg-b-10"
                      onClick={addMoreList}
                    >
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                  <table className="table table-sm align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Product
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Unit
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Qty
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Price
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Description
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(list).map((x, i) => (
                        <tr key={i}>
                          <td>
                            {/* <select
                              className="form-control"
                              name="asset_id"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select product </option>
                              {Array.from(product).map((item, index) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select> */}
                            <ReactDropdown options={product} optitle={"name"} onChange={reactDropdownHandler("asset_id", "id")} />

                          </td>
                          <td>
                            {/* <select
                              className="form-control"
                              name="unit_id"
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="">Select Unit</option>
                              {Array.from(unit).map((item, index) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select> */}
                            <ReactDropdown options={unit} optitle={"name"} onChange={reactDropdownHandler("unit_id", "id")} />

                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="qty"
                              value={x.qty || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              value={x.price || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <textarea
                              className="form-control"
                              name="desc"
                              value={x.desc || ""}
                              onChange={(e) => handleInputChange(e, i)}
                            ></textarea>
                          </td>
                          <td>
                            {list.length > 1 ? (
                              <button
                                className="btn btn-danger btn-block"
                                onClick={() => removeList(i)}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* <div className="row mt-3">
              <div className="col-lg">
                <label>Attachment</label>
                <input
                  type="file"
                  name="attachment"
                  className="form-control"
                  multiple
                  onChange={handleFileChange}
                />
              </div>
            </div> */}

                <div className="row mt-3">
                  <div className="col-6"></div>
                  <div className="col-3"></div>
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
