import $ from "jquery"; // Import jQuery
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const CreateWithoutOrderTransfer = () => {
  const navigate = useNavigate();
  // const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [machines, setMachines] = useState([]); // Store shape types from API
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resProduct = await Http.get("/fg-with-stock");
      setProduct(resProduct.data);

      const resRmpm = await Http.get("/rmpm-with-stock");
      setRmpm(resRmpm.data);

      const combined = [
        ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
        ...resProduct.data.map((item) => ({ ...item, type: "FG" })),
      ];
      setCombinedData(combined);

      const response = await Http.get("/shape"); // Fetch shape data from API
      const data = response.data;
      setShapes(data);
      const resMachine = await Http.get("/machine"); // Fetch shape data from API
      setMachines(resMachine.data);

      // Extract unique shape types from the shape data
      const uniqueShapeTypes = [
        ...new Map(
          data.map((item) => [item.shape_type.id, item.shape_type])
        ).values(),
      ];
      setShapeTypes(uniqueShapeTypes);
      
      const resInvoiceNo = await Http.get("/last-code-increment-by-one/production_finished_good_transfers/receipt_no/PL");
      setInputs((values) => ({
        ...values,
        receipt_no: resInvoiceNo.data,
      }));

    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true); // Use itemLoading to control this specific action
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire(
          "Warning!",
          "This Product is already in the list!",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false); // Stop loading after action is complete
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  const fetchProductsByWorkOrderId = async (workOrderId) => {
    setItemLoading(true);
    try {
      const res = await Http.get(`/production-transfer/${workOrderId}`);
      const workOrderItems = res.data.finished_good.items; // Assuming 'items' contains the product details
  
      const updatedItems = workOrderItems.map(item => ({
        id: item.id,
        product_id: item.product.id, 
        name: item.product.name, 
        qty: item.qty, 
      }));
  
      setList(updatedItems);
    } catch (error) {
      console.error("Error fetching work order products:", error);
      Swal.fire("Error!", "Failed to fetch products for the selected work order.", "error");
    } finally {
      setItemLoading(false);
    }
  };
  

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  
  const selectHandleChange = async (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const { value } = selectedOption;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list;
    // inputs.sr_id = parseInt(inputs.sr_id);
    const updatedList = list.map((item) => ({
      ...item,
      product_id: item.id
    }));

    // Assign the updated list to inputs.items
    inputs.items = updatedList;
    Http.post("/artisan-transfer", inputs)
      .then((res) => {
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };
  const cols = [
    { field: "name", header: "Item" },
    // { field: "type", header: "Type" },
  ];
  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button
          className="btn btn-success btn-sm"
          onClick={() => addMoreList(item.id)}
        >
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };
  
  return (
    <div>
      {/* <IsLogin></IsLogin> */}
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Transfer</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Create Transfer Form
            </h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Transfer No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Invoice No"
                    />

                    {displayError("receipt_no")}
                  </div>

                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />

                    {displayError("date")}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label>
                      Remarks 
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                      placeholder="Remarks"
                    />

                    {displayError("remarks")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Order{" "}
                        <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              #
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                              Product
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Production Qty
                            </th>
                            <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Transfer Qty
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={4}>
                                <p
                                  style={{ color: "red" }}
                                  className="text-center mt-3"
                                >
                                  Please Add Few Products For Transfer
                                </p>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {Array.from(list).map((x, i) => (
                            <tr key={i}>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => removeList(i)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="product"
                                  value={list[i].name}
                                  // onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Product"
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="production_qty"
                                  value={x.qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="qty"
                                  disabled
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="qty"
                                  value={x.qty || ""}
                                  onChange={(e) => handleInputChange(e, i)}
                                  placeholder="Qty"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">
              Available Items
            </h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWithoutOrderTransfer;
