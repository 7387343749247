import axios from "axios";

//let url = "https://erp.ldlerp.com/backend/public/api";
// let url = "http://127.0.0.1:8000/api";
// let url = "https://demo.limerickbd.com/backend/public/api";
// let url = "https://demo.limerickbd.com/artisan-backend/public/api";
let url = "https://artisan.projectsample.info/backend/public/api";

export default axios.create({
  url: url,
  baseURL: url,
  headers: {
    Accept: "application/json",
    // "Content-Type": "multipart/form-data",
    // Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  },
});
