import React from "react";
import Select from "react-select";

const ReceipeDetail = (props) => {
  const removeList = (index) => {
    props.remove(index);
  };
  const handleInputChange = (e, index) => {
    props.listInputChange(e, index);
  };
  return (
    <tr>
      <td>{props.listInfo.code}</td>
      <td>
        {props.listInfo.name ? props.listInfo.name : props.listInfo.product}
      </td>
      <td className="text-right">
        <p style={{ padding: "7px 0px", marginBottom: "0px" }}>
          {props.listInfo.stndrd_qty ? props.listInfo.stndrd_qty : 0}
        </p>
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          name="issue_qty"
          onChange={(e) => handleInputChange(e, props.listSl)}
          value={
            props.listInfo.issue_qty || "0"
          }
        />
      </td>
      <td>
        {props.listDetail.length > 0 ? (
          props.listDetail[props.listSl]
        ) : (
          ""
        )}
      </td>
      {/* <td className="text-center">
        <button
          className="btn btn-danger btn-sm"
          onClick={() => removeList(props.listSl)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td> */}
    </tr>
  );
};

export default ReceipeDetail;
