import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import Http from "../Common/Http";
import IsLogin from "../Common/IsLogin";
import AdminDashboard from "./Dashboard/Admin";
import StaffDashboard from "./Dashboard/Staff";
// import $ from "jquery";

const Dashboard = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/staff-type-check");
        setReceiveData(res.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      {loading ? (
        <ProgressSpinner className="dashLoad" />
      ) : (
        <>
          {(receiveData === "admin" ||
            receiveData === "depot" ||
            receiveData === "supply_chain" ||
            receiveData === "gstore" ||
            receiveData === "body" ||
            receiveData === "jigger" ||
            receiveData === "casting" ||
            receiveData === "finishing" ||
            receiveData === "bk" ||
            receiveData === "glazing" ||
            receiveData === "gk" ||
            receiveData === "selection" ||
            receiveData === "decoration_local" ||
            receiveData === "decoration_export" ||
            receiveData === "dfw" ||
            receiveData === "packing_local" ||
            receiveData === "packing_export" ||
            receiveData === "dstore" ||
            receiveData === "mould" ||
            receiveData === "pstore" ||
            receiveData === "planning" ||
            receiveData === "saggar") && <AdminDashboard />}
          {(receiveData === "staff" ||
            receiveData === "hr" ||
            receiveData === "so") && <StaffDashboard />}
        </>
      )}
    </div>
  );
};

export default Dashboard;
