import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const EditProductionWorkOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  // const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [workOrders, setWorkOrders] = useState([]); // Store work orders from API
  const [unit, setUnit] = useState([]); // Store unit types from API
  const [receiveData, setReceiveData] = useState([]);
  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, [id]);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resProduct = await Http.get("/fg-with-stock");
        setProduct(resProduct.data);

        const resRmpm = await Http.get("/rmpm-with-stock");
        setRmpm(resRmpm.data);

        const combined = [
          ...resRmpm.data.map(item => ({ ...item, type: 'RMPM' })),
          ...resProduct.data.map(item => ({ ...item, type: 'FG' }))
        ];
      setCombinedData(combined);

      const resShape = await Http.get("/shape");
      setShapes(resShape.data);

      const resUnit = await Http.get("/unit");
      setUnit(resUnit.data);

      const resWorkOrders = await Http.get("/production-work-order");
      setWorkOrders(resWorkOrders.data);

      const resReq = await Http.get(`/production-work-order/${id}`);
      const requisitionData = resReq.data;
      setInputs({
        receipt_no: requisitionData.receipt_no || "",
        work_order_date: requisitionData.work_order_date || "",
        delivery_date: requisitionData.delivery_date || "",
      });

      setList(
        requisitionData.items.map((item) => ({
          id: item.id,
          product_id: item.product_id,
          product_name: item.product?.name || "",
          shape_id: item.shape_id,
          shape_type_id: item.shape_type_id,
          qty: item.qty,
          order_number: item.order_number,
          company_id: item.company_id,
        }))
      );
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/product/${id}`);
      if (!list.find((item) => item.id === response.data.id)) {
        setList((prev) => [...prev, response.data]);
      } else {
        Swal.fire("Warning!", "This Product is already in the list!", "warning");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false);
    }
  };

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];
    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list.map((item) => ({
      product_id: item.product_id,
      shape_id: item.shape_id,
      shape_type_id: item.shape_type_id,
      order_number: item.order_number,
      company_id: item.company_id,
      qty: item.qty,
    }));

    Http.put(`/production-work-order/${id}`, inputs)
      .then((res) => {
        navigate("/production-work-order", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const cols = [{ field: "name", header: "Item" }];

  const actionTemplate = (item, deleteData) => {
    return (
      <td className="text-right">
        <button className="btn btn-success btn-sm" onClick={() => addMoreList(item.id)}>
          <i className="fa fa-plus"></i>
        </button>
      </td>
    );
  };

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Production Work Order</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">Edit Work Order Form</h6>
            <hr></hr>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Receipt No <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Input Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Work Order Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="work_order_date"
                      value={inputs.work_order_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />
                    {displayError("work_order_date")}
                  </div>
                  <div className="col-lg">
                    <label>
                      Delivery Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="delivery_date"
                      value={inputs.delivery_date || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                    />
                    {displayError("delivery_date")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">
                        Items in the Order <i className="fa fa-star required"></i>
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Shape</th>
                            <th>Shape Type</th>
                            <th>Order Number</th>
                            <th>Company</th>
                            <th>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={7}>
                                <p style={{ color: "red" }} className="text-center mt-3">
                                  Please Add Products
                                </p>
                              </td>
                            </tr>
                          ) : (
                            list.map((x, i) => (
                              <tr key={i}>
                                <td>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeList(i)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="product"
                                    value={x.product_name}
                                    placeholder="Product"
                                    disabled
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_id"
                                    value={x.shape_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape</option>
                                    {shapes.map((shape) => (
                                      <option key={shape.id} value={shape.id}>
                                        {shape.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_type_id"
                                    value={x.shape_type_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape Type</option>
                                    {shapeTypes.map((type) => (
                                      <option key={type.id} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="order_number"
                                    value={x.order_number || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Order Number"
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="company_id"
                                    value={x.company_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Company</option>
                                    {/* Assuming you have company data */}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="qty"
                                    value={x.qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Qty"
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className="card pd-20 pd-sm-40 col-3"
            style={{ backgroundColor: "#ffffffc7" }}
          >
            <h6 className="card-body-title mg-sm-b-30 text-center">Available Items</h6>
            <hr></hr>
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductionWorkOrder;
