import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import Select from "react-select"; // Importing Select for dropdown
import { ProgressSpinner } from "primereact/progressspinner";

const EditShape = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape type options
  const { checkRequiredFields } = useRequiredFields();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        // Fetch shape details
        const res = await Http.get(`/editShape/${id}`);
        setInputs({
          name: res.data.name,
          code: res.data.code,
          shape_type_id: res.data.shape_type_id,
          status: { value: res.data.status, label: res.data.status === "active" ? "Active" : "Inactive" }, // Setting status
        });

        // Fetch shape types for the dropdown
        const resTypes = await Http.get("/shapeTypeSelect2");
        const options = resTypes.data.map((shape) => ({
          value: shape.value,
          label: shape.label,
        }));
        setShapeTypes(options);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleShapeTypeChange = (option) => {
    setInputs((values) => ({ ...values, shape_type_id: option.value }));
  };

  const handleStatusChange = (option) => {
    setInputs((values) => ({ ...values, status: option }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.put(`/updateShape/${id}`, {
        ...inputs,
        status: inputs.status.value, // Send only the status value
      })
        .then((res) => {
          navigate("/shape", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  // Status options
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Shape</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Shape Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Shape Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Code<i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                      placeholder="Input Shape Code"
                      data-required
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Shape Type <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="shape_type_id"
                      options={shapeTypes} // Populate options from state
                      value={shapeTypes.find((opt) => opt.value === inputs.shape_type_id) || null} // Set selected value
                      onChange={handleShapeTypeChange} // Handle dropdown change
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Status <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="status"
                      options={statusOptions} // Options for active/inactive
                      value={inputs.status || null}
                      onChange={handleStatusChange} // Handle status dropdown change
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default EditShape;
