import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDropdown from "../../../Common/ReactDropdown";
import ReactMultiDrop from "../../../Common/ReactMultiDrop";

// =============Need Further R&D with Multiselect from prime react ===============

const CreateRegion = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  const [list, setList] = useState([{ desc: "", date: "" }]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [staff, setStaff] = useState({});
  const { checkRequiredFields } = useRequiredFields();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const selectHandleChange = (selectedValues) => {
    setSelectedStaff(selectedValues);
  };

  // const fetchData = () => {
  //   Http.get("/roaster-holiday/" + id + "/edit").then((res) => {
  //     setInputs({
  //       name: res.data.name,
  //       year: res.data.year,
  //     });

  //     const listInfo = [];
  //     Object.keys(res.data.items).forEach((key) => {
  //       listInfo.push({
  //         date: res.data.items[key].date,
  //         desc: res.data.items[key].desc,
  //       });
  //     });
  //     setList(listInfo);

  //     const staffInfo = [];
  //     Object.keys(res.data.users).forEach((key) => {
  //       staffInfo.push({
  //         label: res.data.users[key].name,
  //         value: res.data.users[key].id,
  //       });
  //     });
  //     console.log(staffInfo);
  //     setSelectedStaff(staffInfo);
  //   });

  //   Http.get("/staff2").then((res) => {
  //     setStaff(res.data);
  //   });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetching roaster-holiday data
        const response = await Http.get(`/roaster-holiday/${id}/edit`);
        const data = response.data;

        setInputs({
          name: data.name,
          year: data.year,
        });

        const listInfo = Object.keys(data.items).map((key) => ({
          date: data.items[key].date,
          desc: data.items[key].desc,
        }));
        setList(listInfo);

        const staffInfo = Object.keys(data.users).map((key) => ({
          label: data.users[key].name,
          value: data.users[key].id,
        }));
        setSelectedStaff(staffInfo);

        // Fetching staff data
        const staffResponse = await Http.get("/staff2");
        setStaff(staffResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Add More List
  const addMoreList = () => {
    setList([...list, { date: "", desc: "" }]);
  };

  // Remove List
  const removeList = (index) => {
    // Load All Existing experience State Data
    const inputInfo = [...list];
    // Remove Experience by Index/Click Event Wise
    inputInfo.splice(index, 1);
    // Replace Existing experience into State
    setList(inputInfo);
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      // inputs.list = list;
      // inputs.staff_list = selectedStaff;
      Http.put(`/roaster-holiday/${id}`, { ...inputs, list, staff_list: selectedStaff })
        .then((res) => {
          navigate("/roaster-holiday-list", {
            state: { message: res.data.message, type: res.data.type },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };


  // don't remove following portion for multiselect(Alvi)
  
  // const reactDropdownHandler = (field, prop) => (selectedOptions) => {
  //   const selectedValues = selectedOptions.map(option => option.value);
  //   setInputs((values) => ({
  //     ...values,
  //     [field]: selectedValues,
  //   }));
  //   setSelectedStaff(selectedOptions);
  // };


  // const reactDropdownHandler = (field) => (selectedOptions) => {
  //   // Log selectedOptions for debugging
  //   console.log("Selected Options:", selectedOptions);
  //   console.log("=========Selected Options Field:", field);

  
  //   // Check if selectedOptions is in the correct format
  //   if (Array.isArray(selectedOptions)) {
  //     // Format the selected options
  //     const formattedOptions = selectedOptions.map(option => ({
  //       label: option.label,
  //       value: option.value
  //     }));
  
  //     // Update the state with the formatted options
  //     setInputs((values) => ({
  //       ...values,
  //       [field]: formattedOptions,
  //     }));
  
  //     // Update selectedStaff with the new array
  //     setSelectedStaff(formattedOptions);
  //   } else {
  //     // Handle cases where selectedOptions is not an array
  //     console.error("Unexpected format for selectedOptions:", selectedOptions);
  //   }
  // };
//  nicher ei portion tao use krte hbe uporer function e 
  // const selectHandleChange = (selectedOption, actionMeta) => {
  //   setSelectedRegionOption({
  //     label: selectedOption.label,
  //     value: selectedOption.value,
  //   });

  //   const name = actionMeta.name;
  //   const value = selectedOption.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };
  
  
  

  return (
    <div>
      <IsLogin></IsLogin>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Roaster Holiday Calander</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Edit Roaster Holiday</h6>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <FormValidation error={error} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Name"
                      data-required
                    />
                  </div>
                  {/* col */}
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Year <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="year"
                      value={inputs.year || ""}
                      onChange={handleChange}
                      placeholder="Input Date"
                      data-required
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">Staff</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={selectedStaff}
                      value={selectedStaff}
                      name="hr_staff_basic_info_id"
                      options={staff}
                      text="Please select user."
                      isMulti="yes"
                      onChange={selectHandleChange}
                    />
                     {/* <ReactMultiDrop
                      options={staff}
                      optitle={"label"}
                      val={selectedStaff.map(staff => staff.value)}
                      onChange={reactDropdownHandler("hr_staff_basic_info_id")}
                    /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 mt-4"></div>
                </div>

                {Array.from(list).map((x, i) => (
                  <div className="row mt-3" key={i}>
                    <div className="col-lg">
                      <div className="row">
                        <div className="col-6">
                          <label className="font-weight-bold">Date</label>
                          <input type="date" className="form-control" name="date" value={x.date || ""} onChange={(e) => handleInputChange(e, i)} />
                        </div>
                        <div className="col-5">
                          <label className="font-weight-bold">Remarks</label>
                          <input type="text" className="form-control" name="desc" value={x.desc || ""} onChange={(e) => handleInputChange(e, i)} />
                        </div>
                        <div className="col-1 mt-4 pt-2">
                          {list.length > 1 ? (
                            <button className="btn btn-danger btn-sm" onClick={() => removeList(i)}>
                              <i className="fa fa-trash"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="row mt-5">
                  <div className="col-6 text-right">
                    <button className="btn btn-primary mg-b-10" onClick={formSubmit} style={{ width: "50%" }}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                  <div className="col-6 ">
                    <button className="btn btn-info mg-b-10" onClick={addMoreList} style={{ width: "50%" }}>
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                </div>

                {/* <div className="row mt-3">
                  <div className="col-6 text-right">
                    <button className="btn btn-primary mg-b-10" onClick={formSubmit} style={{ width: "50%" }}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                  <div className="col-3">
                  <button className="btn btn-info mg-b-10" onClick={addMoreList} style={{ width: "50%" }}>
                      <i className="fa fa-plus mg-r-10"></i> ADD More
                    </button>
                  </div>
                </div> */}
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateRegion;
