import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDropdown from "../../../Common/ReactDropdown";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [unit, setUnit] = useState({});
  const [category, setCategory] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/unit");
        setUnit(res.data);
        const res2 = await Http.get("/asset-active-category");
        setCategory(res2.data);
        const res3 = await Http.get(
          "/last-code-increment-by-one/assets/code/A"
        );
        setInputs((values) => ({ ...values, ["code"]: res3.data }));
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // Default Load
  // const fetchData = () => {
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  //   Http.get("/asset-active-category").then((res) => {
  //     setCategory(res.data);
  //   });
  //   Http.get("/last-code-increment-by-one/assets/code/A").then((res) => {
  //     setInputs((values) => ({ ...values, ["code"]: res.data }));
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  // End Default Load

  const formSubmit = () => {
    Http.post("/asset", inputs)
      .then((res) => {
        navigate("/asset", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: prop ? e.value[prop] : e.value,
    }));
  };

  const status = [
    { statusName: "Active", statusValue: "active" },
    { statusName: "Inactive", statusValue: "inactive" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Asset</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Asset Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>Code</label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">
                      Unit <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="unit_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Unit</option>
                      {Array.from(unit).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown
                      options={unit}
                      optitle={"name"}
                      onChange={reactDropdownHandler("unit_id", "id")}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label htmlFor="">
                      Category <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="asset_category_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Category</option>
                      {Array.from(category).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown
                      options={category}
                      optitle={"name"}
                      onChange={reactDropdownHandler("asset_category_id", "id")}
                    />
                  </div>

                  <div className="col-lg">
                    <label>Notes</label>
                    <textarea
                      className="form-control"
                      name="notes"
                      value={inputs.notes || ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="col-lg-4">
                    <label>Status</label>
                    {/* <select
                      className="form-control"
                      name="status"
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select> */}
                    <ReactDropdown
                      options={status}
                      optitle={"statusName"}
                      onChange={reactDropdownHandler("status", "statusValue")}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
