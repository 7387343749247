import $ from "jquery"; // Import jQuery
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import ReactDataTable from "../../../Common/ReactDataTable";

const EditProductionTransfer = () => {
  const { id } = useParams(); // Get the ID from the URL
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemLoading, setItemLoading] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [shapeTypes, setShapeTypes] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [requisition, setRequisition] = useState([]);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);

  const [product, setProduct] = useState([]);
  const [rmpm, setRmpm] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const lossTypes = ['BN', 'IS', 'GD'];
  const repairTypes = ['PH', 'SS'];

  useEffect(() => {
    fetchInitialData();
    $("body").toggleClass("collapse-menu");
    $("body").toggleClass("show-left");
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const resProduct = await Http.get("/fg-with-stock");
      const resRmpm = await Http.get("/rmpm-with-stock");
      const combined = [
        ...resRmpm.data.map((item) => ({ ...item, type: "RMPM" })),
        ...resProduct.data.map((item) => ({ ...item, type: "FG" })),
      ];
      setCombinedData(combined);
      
      setShapes((await Http.get("/shape")).data);
      setShapeTypes((await Http.get("/shapetype")).data);
      setWorkOrders((await Http.get("/production-work-order")).data);
      setRequisition((await Http.get("/requisition-art")).data);

      // Fetch the existing data by ID
      const resExisting = await Http.get(`/production-transfer/${id}`);
      const { finished_good, transfer } = resExisting.data;

      setInputs({
        receipt_no: finished_good.receipt_no,
        requisition_id: finished_good.requisition_id,
        production_work_order_id: finished_good.production_work_order_id,
        production_date: finished_good.production_date,
        from_department: finished_good.department,
        transfer_date: transfer.transfer_date,
        receive_date: transfer.receive_date,
        items: finished_good.items.map((item, index) => ({
          ...item,
          product_id: item.product_id,
          shape_id: item.shape_id,
          shape_type_id: item.shape_type_id,
          qty: item.qty,             // Coming from finished_good
          loss_qty: item.loss_qty,   // Coming from finished_good
          repair_qty: item.repair_qty, // Coming from finished_good
          to_department: transfer.items[index]?.to_department, // Use transfer's department
          decoration: item.decoration,     // Coming from finished_good
          remarks: item.remarks,     // Coming from finished_good
        })),
      });
      
      setList(finished_good.items.map((item, index) => ({
        ...item,
        to_department: transfer.items[index]?.to_department || "", // Link the transfer's to_department
      })));      

      // setList(transfer.items);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  // const addMoreList = async (id) => {
  //   setItemLoading(true); // Use itemLoading to control this specific action
  //   try {
  //     const response = await Http.get(`/product/${id}`);
  //     if (!list.find((item) => item.id === response.data.id)) {
  //       setList((prev) => [...prev, response.data]);
  //     } else {
  //       Swal.fire(
  //         "Warning!",
  //         "This Product is already in the list!",
  //         "warning"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching product:", error);
  //     Swal.fire("Error!", "Failed to add product.", "error");
  //   } finally {
  //     setItemLoading(false); // Stop loading after action is complete
  //   }
  // };

  // const addMoreList = async (id) => {
  //   setItemLoading(true);
  //   try {
  //     const response = await Http.get(`/product/${id}`);
  //     if (!list.find((item) => item.id === response.data.id)) {
  //       const newItem = {
  //         ...response.data,
  //         from_department: inputs.from_department || "", // Assign the default `from_department`
  //       };
  //       setList((prev) => [...prev, newItem]);
  //     } else {
  //       Swal.fire("Warning!", "This Product is already in the list!", "warning");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching product:", error);
  //     Swal.fire("Error!", "Failed to add product.", "error");
  //   } finally {
  //     setItemLoading(false);
  //   }
  // };

  const addMoreList = async (id) => {
    setItemLoading(true);
    try {
      const response = await Http.get(`/product/${id}`);
      const existingItem = list.find((item) => item.id === response.data.id);
  
      if (!existingItem) {
        // Structure the new item similarly to existing items
        const newItem = {
          id: response.data.id, // Ensure the correct id is set
          product: {
            id: response.data.id, // The new product's ID
            name: response.data.name, // The new product's name
            // Add other necessary fields if required to match the structure
          },
          product_id: response.data.id, // Ensure product_id is set like in existing items
          shape_id: null, // Set default values for other fields
          shape_type_id: null,
          qty: 0, // Ensure qty field exists
          loss_qty: 0, // Ensure loss_qty field exists
          repair_qty: 0, // Ensure repair_qty field exists
          to_department: "", // Ensure to_department field exists
          remarks: "", // Ensure remarks field exists
        };
  
        setList((prev) => [...prev, newItem]); // Add the new structured item to the list
      } else {
        Swal.fire("Warning!", "This Product is already in the list!", "warning");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      Swal.fire("Error!", "Failed to add product.", "error");
    } finally {
      setItemLoading(false);
    }
  };
  

  const removeList = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedList = list.filter((_, i) => i !== index);
        setList(updatedList);
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = !isNaN(value) && value !== "" ? parseInt(value) : value;
    setInputs((values) => ({ ...values, [name]: parsedValue }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];
    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  const formSubmit = () => {
    inputs.items = list.map((item) => {
      // Check if the item has a `product` object (existing item)
      const isExistingItem = !!item.product;
  
      return {
        ...item,
        // For existing items, use `product.id`; for new items, use `product_id` directly or `id` as fallback
        product_id: isExistingItem ? item.product.id : item.product_id || item.id,
        shape_id: item.shape_id || null,
        shape_type_id: item.shape_type_id || null,
        delivery_qty: item.qty || 0,
        receive_qty: item.receive_qty || 0,
        qty: item.qty || 0,
        loss_qty: item.loss_qty || 0,
        loss_type: item.loss_type || "",
        repair_qty: item.repair_qty || "",
        repair_type: item.repair_type || "",
        from_department: item.from_department || inputs.from_department,
        to_department: item.to_department || "",
        decoration: item.decoration || "",
        remarks: item.remarks || "",
      };
    });
  
    Http.put(`/production-transfer/${id}`, inputs)
      .then((res) => {
        navigate("/production-transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };
  
  

  const displayError = (fieldName) => {
    if (error && error[fieldName]) {
      return <div className="text-danger small">{error[fieldName]}</div>;
    }
    return null;
  };

  const cols = [{ field: "name", header: "Item" }];

  const actionTemplate = (item) => (
    <td className="text-right">
      <button className="btn btn-success btn-sm" onClick={() => addMoreList(item.id)}>
        <i className="fa fa-plus"></i>
      </button>
    </td>
  );

  return (
    <div>
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Production Transfer</h5>
        </div>
        <div className="am-pagebody row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-8">
            <h6 className="card-body-title mg-sm-b-30 text-center">Production Form</h6>
            <hr />
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>Receipt No <i className="fa fa-star required"></i></label>
                    <input
                      type="text"
                      className="form-control"
                      name="receipt_no"
                      value={inputs.receipt_no || ""}
                      onChange={handleChange}
                      placeholder="Receipt No"
                    />
                    {displayError("receipt_no")}
                  </div>
                  <div className="col-lg">
                    <label>Requisition<i className="fa fa-star required"></i></label>
                    <select
                      className="form-control"
                      name="requisition_id"
                      value={inputs.requisition_id || ""}
                      onChange={handleChange}
                    >
                      <option value="">Select Requisition</option>
                      {requisition.map((order) => (
                        <option key={order.id} value={order.id}>
                          {order.receipt_no}
                        </option>
                      ))}
                    </select>
                    {displayError("requisition_id")}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="card-solid">
                    <div className="card-header with-border text-center">
                      <h5 className="card-title mb-0">Items in the Production</h5>
                    </div>
                    <div className="card-body p-0">
                      <table className="table table-sm table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Shape</th>
                            <th>Shape Type</th>
                            <th>Production Qty</th>
                            <th>Loss Qty</th>
                            <th>Loss Type</th>
                            <th>Repair Qty</th>
                            <th>Repair Type</th>
                            <th>To Department</th>
                            <th>Decoration</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.length < 1 ? (
                            <tr>
                              <td colSpan={11} className="text-center">No items found</td>
                            </tr>
                          ) : (
                            list.map((x, i) => (
                              <tr key={i}>
                                <td>
                                  <button className="btn btn-danger btn-sm" onClick={() => removeList(i)}>
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                {/* <td>{x.product.name || x.name}</td> */}
                                <td>{x.product ? x.product.name : x.name}</td> {/* Add this check */}
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_id"
                                    value={x.shape_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape</option>
                                    {shapes.map((shape) => (
                                      <option key={shape.id} value={shape.id}>
                                        {shape.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="shape_type_id"
                                    value={x.shape_type_id || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Shape Type</option>
                                    {shapeTypes.map((type) => (
                                      <option key={type.id} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="qty"
                                    value={x.qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Required Qty"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="loss_qty"
                                    value={x.loss_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Loss Qty"
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="loss_type"
                                    value={x.loss_type || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Loss Type</option>
                                    {lossTypes.map((type) => (
                                      <option key={type} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="repair_qty"
                                    value={x.repair_qty || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Repair Qty"
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="repair_type"
                                    value={x.repair_type || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select Repair Type</option>
                                    {repairTypes.map((type) => (
                                      <option key={type} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-control"
                                    name="to_department"
                                    value={x.to_department || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                  >
                                    <option value="">Select To Department</option>
                                    {departments.map((dept) => (
                                      <option key={dept.id} value={dept.id}>
                                        {dept.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="decoration"
                                    value={x.decoration || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Decoration"
                                  />
                                </td>
                                <td>
                                  <textarea
                                    className="form-control"
                                    name="remarks"
                                    value={x.remarks || ""}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder="Remarks"
                                  />
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Update Transfer
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card pd-20 pd-sm-40 col-3" style={{ backgroundColor: "#ffffffc7" }}>
            <h6 className="card-body-title mg-sm-b-30 text-center">Available Items</h6>
            <hr />
            <ReactDataTable
              cols={cols}
              receiveData={combinedData}
              isLoading={loading}
              actionTemplate={actionTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductionTransfer;
