import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});

  const [shapes, setShapes] = useState([]); // Store shapes from API
  const [shapeTypes, setShapeTypes] = useState([]); // Store shape types from API
  const [units, setUnits] = useState([]); // Store units from API
  const [role, setRole] = useState("");
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);

  const defectTypes = ["BN", "IS", "PH", "GD", "UG"];
  const repairTypes = ["PH", "SS", "AIR", "PC"];

  // Fetch the requisition data by ID and populate the form inputs
  const fetchData = () => {
    Http.get("/staff-type-check").then((res) => {
      setRole(res.data);
    });
    Http.get("/requisition-art/" + id)
      .then((res) => {
        const requisitionData = res.data;

        // Populate inputs with the response data
        setInputs({
          receipt_no: requisitionData.receipt_no || "",
          production_work_order_id:
            requisitionData.production_work_order_id || "",
          date: new Date().toISOString().split("T")[0], // Returns date in localized string format (e.g., "10/17/2023" in MM/DD/YYYY format)
          receive_date: requisitionData.receive_date || "",
          copy_type: requisitionData.copy_type || "",
          purchase_type: requisitionData.purchase_type || "",
          from_department: requisitionData.from_department || "",
          to_department: requisitionData.to_department || "",
          status: requisitionData.status || "requested",
          remarks: requisitionData.remarks || "",
        });

        // Populate list (items) with the response data, setting delivery_qty for validation
        const itemsList = requisitionData.items.map((item) => {
          return {
            id: item.id,
            product_id: item.product_id,
            product_name: item.product.name || "",
            unit_id: item.unit_id,
            shape_id: item.shape_id,
            shape_type_id: item.shape_type_id,
            in_hand_qty: item.in_hand_qty || 0,
            in_pipeline_qty: item.in_pipeline_qty || 0,
            on_order_qty: item.on_order_qty || 0,
            required_qty: item.required_qty || 0,
            delivery_qty:
              item.delivery_qty !== null
                ? item.delivery_qty
                : item.required_qty,
            receive_qty: null,
            mould_qty_per_set: item.mould_qty_per_set || 0,
            required_time: item.required_time || "",
            estimated_unit_price: item.product.price || "0.00",
            estimated_total_price: item.estimated_total_price || "0.00",
            avg_monthly_consumption: item.avg_monthly_consumption || "",
            decoration: item.decoration || "",
            remarks_delivery: item.remarks_delivery || "",
          };
        });

        setList(itemsList);
      })
      .catch((e) => {
        console.error("Error fetching data:", e);
      });

    // Fetch shapes from API
    Http.get("/shape")
      .then((res) => {
        setShapes(res.data); // Store shapes response
      })
      .catch((e) => {
        console.error("Error fetching shapes:", e);
      });

    // Fetch shape types from API
    Http.get("/shapetype")
      .then((res) => {
        setShapeTypes(res.data); // Store shape types response
      })
      .catch((e) => {
        console.error("Error fetching shape types:", e);
      });
    // Fetch units from API
    Http.get("/unit")
      .then((res) => {
        setUnits(res.data); // Store units response
      })
      .catch((e) => {
        console.error("Error fetching units:", e);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];

  //   if (name === "delivery_qty") {
  //     const deliverQty = parseFloat(value);
  //     const remainingQty = updatedList[index].required_qty;

  //     if (deliverQty < 1) {
  //       alert("Deliver quantity cannot be less than 1.");
  //       return;
  //     } else if (deliverQty > remainingQty) {
  //       alert(`Deliver quantity cannot exceed ${remainingQty}.`);
  //       return;
  //     }

  //     updatedList[index][name] = deliverQty;

  //     const allItemsDelivered = updatedList.every(
  //       (item) => item.delivery_qty === item.required_qty
  //     );

  //     if (allItemsDelivered) {
  //       setInputs((values) => ({ ...values, status: "delivered" }));
  //     } else {
  //       setInputs((values) => ({ ...values, status: "requested" }));
  //     }
  //   } else {
  //     updatedList[index][name] = value;
  //   }

  //   // Calculate total_p, total_t, and closing_balance based on updated values
  //   const opening_balance = parseFloat(updatedList[index].opening_balance) || 0;
  //   const received_qty = parseFloat(updatedList[index].received_qty) || 0;
  //   const biscuit_loss = parseFloat(updatedList[index].biscuit_loss) || 0;
  //   const delivery_qty = parseFloat(updatedList[index].delivery_qty) || 0;

  //   updatedList[index].total_p = opening_balance + received_qty;
  //   updatedList[index].total_t = biscuit_loss + delivery_qty;
  //   updatedList[index].closing_balance =
  //     updatedList[index].total_p - updatedList[index].total_t;

  //   setList(updatedList);
  // };

  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedList = [...list];
  //   const inputValue = parseFloat(value) || 0;

  //   setList(updatedList);
  // };

  // const handleAddType = (index, type) => {
  //   const updatedList = [...list];
  //   const newEntry = { type: "", qty: 0 };

  //   if (type === "defect") {
  //     updatedList[index].defects = [
  //       ...(updatedList[index].defects || []),
  //       newEntry,
  //     ];
  //   } else if (type === "repair") {
  //     updatedList[index].repairs = [
  //       ...(updatedList[index].repairs || []),
  //       newEntry,
  //     ];
  //   }

  //   setList(updatedList);
  // };

  // const handleTypeChange = (index, subIndex, value, type) => {
  //   const updatedList = [...list];
  //   if (type === "defect") {
  //     updatedList[index].defects[subIndex].type = value;
  //   } else if (type === "repair") {
  //     updatedList[index].repairs[subIndex].type = value;
  //   }
  //   setList(updatedList);
  // };

  // const handleQtyChange = (index, subIndex, value, type) => {
  //   const updatedList = [...list];
  //   const quantity = parseInt(value) || 0;
  //   if (type === "defect") {
  //     updatedList[index].defects[subIndex].qty = quantity;
  //   } else if (type === "repair") {
  //     updatedList[index].repairs[subIndex].qty = quantity;
  //   }
  //   setList(updatedList);
  // };

  // const handleRemoveType = (index, subIndex, type) => {
  //   const updatedList = [...list];
  //   if (type === "defect") {
  //     updatedList[index].defects.splice(subIndex, 1);
  //   } else if (type === "repair") {
  //     updatedList[index].repairs.splice(subIndex, 1);
  //   }
  //   setList(updatedList);
  // };

  const decorationOptions = ["Option 1", "Option 2", "Option 3"];

  const packingTypeOptions = ["Type 1", "Type 2", "Type 3"];

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // Enforce non-negative values
    if (inputValue < 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid Input",
        text: `${name} cannot be less than 0.`,
      });
      inputValue = 0;
    }

    // Update the specific field
    updatedList[index][name] = inputValue;

    // Calculate total_p, total_t, and closing_balance based on updated values
    const opening_balance = parseFloat(updatedList[index].opening_balance);
    const received_qty = parseFloat(updatedList[index].received_qty);
    let biscuit_loss = parseFloat(updatedList[index].biscuit_loss);
    let delivery_qty = parseFloat(updatedList[index].delivery_qty);

    // Calculate total_p
    updatedList[index].total_p = opening_balance + received_qty;

    // Check if biscuit_loss and delivery_qty together would exceed total_p
    if (
      name === "biscuit_loss" &&
      biscuit_loss + delivery_qty > updatedList[index].total_p
    ) {
      // Adjust biscuit_loss so that total_t does not exceed total_p
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: `Biscuit Loss cannot be set to ${inputValue} as it would cause Total Transfer (total_t) to exceed Total Production (total_p). Adjusting to maximum allowed value.`,
      });
      biscuit_loss = updatedList[index].total_p - delivery_qty;
      updatedList[index].biscuit_loss = biscuit_loss;
    }

    // Calculate total_t based on adjusted biscuit_loss and current delivery_qty
    updatedList[index].total_t = biscuit_loss + delivery_qty;

    // Ensure total_t does not exceed total_p
    if (updatedList[index].total_t > updatedList[index].total_p) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Total Transfer (total_t) cannot be greater than Total Production (total_p).",
      });
      updatedList[index].total_t = updatedList[index].total_p;
      updatedList[index].delivery_qty =
        updatedList[index].total_p - biscuit_loss; // Adjust delivery_qty accordingly
    }

    // Calculate closing_balance
    updatedList[index].closing_balance =
      updatedList[index].total_p - updatedList[index].total_t;

    setList(updatedList);
  };

  const formSubmit = () => {
    const formData = {
      ...inputs,
      items: list,
    };

    Http.put(`/requisition-art/${id}`, formData)
      .then((res) => {
        navigate("/requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  // Function to get department name by ID
  const getDepartmentName = (id) => {
    const department = departments.find((dept) => dept.id === id);
    return department ? department.name : "N/A";
  };

  // Function to get shape name by ID
  const getShapeName = (id) => {
    const shape = shapes.find((shape) => shape.id === id);
    return shape ? shape.name : "N/A";
  };

  const getShapeTypeName = (id) => {
    const shapeType = shapeTypes.find((type) => type.id === id);
    return shapeType ? shapeType.name : "N/A";
  };

  const getUnitName = (id) => {
    const unit = units.find((unit) => unit.id === id);
    return unit ? unit.name : "N/A";
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production and Deliver</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Production and Delivery Form
            </h6>
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>
              {/* <div className="col-lg">
                <label>Production Work Order</label>
                <input
                  type="text"
                  className="form-control"
                  name="production_work_order_id"
                  value={inputs.production_work_order_id}
                  disabled
                />
              </div> */}
              <div className="col-lg">
                <label>Received Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="receive_date"
                  value={inputs.receive_date}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div>
              {/* {(role == 'mould' || role == 'sagger' || role == 'gstore' || role == 'dstore' || role == 'pstore') && ( <> */}
              {/* <div className="col-lg">
                <label>Copy Type</label>
                <select
                  className="form-control"
                  name="copy_type"
                  value={inputs.copy_type}
                  disabled
                >
                  <option value="original">Original</option>
                  <option value="duplicate">Duplicate</option>
                  <option value="triplicate">Triplicate</option>
                </select>
              </div>

              <div className="col-lg">
                <label>Purchase Type</label>
                <select
                  className="form-control"
                  name="purchase_type"
                  value={inputs.purchase_type}
                  disabled
                >
                  <option value="local">Local</option>
                  <option value="foreign">Foreign</option>
                </select>
              </div> */}

              {/* </>)} */}

              <div className="col-lg">
                <label>Transfer To Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="to_department"
                  value={getDepartmentName(inputs.to_department)} // Get department name
                  disabled
                />
              </div>

              {/* <div className="col-lg">
                <label>To Department</label>
                <input
                  type="text"
                  className="form-control"
                  name="to_department"
                  value={getDepartmentName(inputs.to_department)} // Get department name
                  disabled
                />
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                  disabled
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Work Order</th>
                    <th>Shape</th>
                    <th>Code</th>
                    <th>Decoration</th>
                    <th>Opening Balance</th>

                    {/* Export Class Qty */}
                    {(role === "decoration_export" ||
                      role === "packing_export" ||
                      role === "admin") && <th>Export Class Qty</th>}

                    {/* A, B, C Class Qty - Only for decoration_local and admin */}
                    {(role === "decoration_local" ||
                      role === "packing_local" ||
                      role === "admin") && (
                      <>
                        <th>A Class Qty</th>
                        <th>B Class Qty</th>
                        <th>C Class Qty</th>
                      </>
                    )}

                    {/* Export Class Loss Qty */}
                    {(role === "decoration_export" ||
                      role === "packing_export" ||
                      role === "admin") && <th>Export Class Loss Qty</th>}

                    {/* A, B, C Class Loss Qty - Only for decoration_local and admin */}
                    {(role === "decoration_local" ||
                      role === "packing_local" ||
                      role === "admin") && (
                      <>
                        <th>A Class Loss Qty</th>
                        <th>B Class Loss Qty</th>
                        <th>C Class Loss Qty</th>
                      </>
                    )}

                    {/* Export Class Delivery Qty */}
                    {(role === "decoration_export" ||
                      role === "packing_export" ||
                      role === "admin") && <th>Export Class Delivery Qty</th>}

                    {/* A, B, C Class Delivery Qty - Only for decoration_local and admin */}
                    {(role === "decoration_local" ||
                      role === "packing_local" ||
                      role === "admin") && (
                      <>
                        <th>A Class Delivery Qty</th>
                        <th>B Class Delivery Qty</th>
                        <th>C Class Delivery Qty</th>
                      </>
                    )}

                    <th>Closing Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => (
                    <tr key={index}>
                      {/* Product Name */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="product_name"
                          value={item.product_name}
                          disabled
                        />
                      </td>

                      {/* Work Order */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="work_order"
                          value={item.work_order}
                          disabled
                        />
                      </td>

                      {/* Shape */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="shape"
                          value={item.shape}
                          disabled
                        />
                      </td>

                      {/* Code */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="code"
                          value={item.code}
                          disabled
                        />
                      </td>

                      {/* Decoration */}
                      <td>
                        <select
                          className="form-control"
                          name="decoration"
                          value={item.decoration}
                          onChange={(e) => handleInputChange(e, index)}
                        >
                          <option value="">Select Decoration</option>
                          {decorationOptions.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>

                      {/* Opening Balance */}
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="opening_balance"
                          value={item.opening_balance}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>

                      {/* Export Class Qty */}
                      {/* Export Class Qty */}
                      {(role === "decoration_export" ||
                        role === "packing_export" ||
                        role === "admin") && (
                        <td>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              name="export_class_qty"
                              value={item.export_class_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            {(role === "packing_export" ||
                              role === "admin") && (
                              <select
                                className="form-select"
                                name="export_packing_type"
                                onChange={(e) => handleInputChange(e, index)}
                              >
                                <option value="">Select</option>
                                {packingTypeOptions.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </td>
                      )}

                      {/* A Class Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              name="a_class_qty"
                              value={item.a_class_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            {(role === "packing_local" || role === "admin") && (
                              <select
                                className="form-select"
                                name="a_class_packing_type"
                                onChange={(e) => handleInputChange(e, index)}
                              >
                                <option value="">Select</option>
                                {packingTypeOptions.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </td>
                      )}

                      {/* B Class Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              name="b_class_qty"
                              value={item.b_class_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            {(role === "packing_local" || role === "admin") && (
                              <select
                                className="form-select"
                                name="b_class_packing_type"
                                onChange={(e) => handleInputChange(e, index)}
                              >
                                <option value="">Select</option>
                                {packingTypeOptions.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </td>
                      )}

                      {/* C Class Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <div className="input-group">
                            <input
                              type="number"
                              className="form-control"
                              name="c_class_qty"
                              value={item.c_class_qty}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            {(role === "packing_local" || role === "admin") && (
                              <select
                                className="form-select"
                                name="c_class_packing_type"
                                onChange={(e) => handleInputChange(e, index)}
                              >
                                <option value="">Select</option>
                                {packingTypeOptions.map((option, idx) => (
                                  <option key={idx} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </td>
                      )}

                      {/* Export Class Loss Qty */}
                      {(role === "decoration_export" ||
                        role === "packing_export" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="export_class_loss_qty"
                            value={item.export_class_loss_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* A Class Loss Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="a_class_loss_qty"
                            value={item.a_class_loss_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* B Class Loss Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="b_class_loss_qty"
                            value={item.b_class_loss_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* C Class Loss Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="c_class_loss_qty"
                            value={item.c_class_loss_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* Export Class Delivery Qty */}
                      {(role === "decoration_export" ||
                        role === "packing_export" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="export_class_delivery_qty"
                            value={item.export_class_delivery_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* A Class Delivery Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="a_class_delivery_qty"
                            value={item.a_class_delivery_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* B Class Delivery Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="b_class_delivery_qty"
                            value={item.b_class_delivery_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* C Class Delivery Qty */}
                      {(role === "decoration_local" ||
                        role === "packing_local" ||
                        role === "admin") && (
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="c_class_delivery_qty"
                            value={item.c_class_delivery_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      )}

                      {/* Closing Balance */}
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="closing_balance"
                          value={item.closing_balance}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Submit Button */}
            <div className="row mt-3">
              <div className="col-3">
                <button className="btn btn-primary" onClick={formSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
