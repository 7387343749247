import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const ShowProductionWorkOrder = () => {
  const [receiveData, setReceiveData] = useState({});
  const [shapes, setShapes] = useState([]);
  // const [shapeTypes, setShapeTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { id } = useParams();
  const [alert, setAlert] = useState(null);

  // Fetch production work order, shape, shape type, and company data
  const fetchData = async () => {
    try {
      const [workOrderRes, shapesRes, companiesRes] = await Promise.all([
        Http.get(`/production-work-order/${id}`),
        // Http.get("/shape"),
        // Http.get("/shapetype"),
        // Http.get("/companies"),
      ]);

      setReceiveData(workOrderRes.data); // Set production work order data
      // setShapes(shapesRes.data); // Set shapes data
      // setShapeTypes(shapeTypesRes.data); // Set shape types data
      // setCompanies(companiesRes.data); // Set companies data
    } catch (error) {
      Swal.fire("Error", "Failed to fetch data. Please try again.", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  const print = () => {
    window.print();
  };

  const dataList = receiveData.items || [];

  // Helper functions to find names by their respective IDs
  // const findShapeName = (shapeId) =>
  //   shapes.find((shape) => shape.id === shapeId)?.name || "N/A";
  // // const findShapeTypeName = (shapeTypeId) =>
  // //   shapeTypes.find((shapeType) => shapeType.id === shapeTypeId)?.name || "N/A";
  // const findCompanyName = (companyId) =>
  //   companies.find((company) => company.id === companyId)?.name || "N/A";

  return (
    <div>
      <IsLogin />
      <LoaderComponent />
      <style>
        {`
          @media print {
              body * { visibility: hidden; font-size:10px;}
              .no-print { visibility: hidden !important; }
              #printable-content, #printable-content * { visibility: visible; }
              #printable-content { position: absolute; left: 0; top: 0; width: 100%; }
              .main-card { padding: 0px; }
              .am-pagebody { padding: 0px; }
              .am-mainpanel { margin-top: 0px; }
              .signature_section .table td{ border-bottom: 0;}
          }
          .main-card { padding: 40px; }
        `}
      </style>

      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Production Work Order</h5>
        </div>

        <div className="am-pagebody">
          <Alert alert={alert} />
          <div className="card card-primary card-outline">
            <div className="card-header noPrint">
              <div className="row">
                <div className="col-md-7">
                  <h4 className="card-title">
                    Receipt No - {receiveData.receipt_no}
                  </h4>
                </div>
                <div className="col-md-5 text-right">
                  <button className="btn btn-default" onClick={print}>
                    <i className="fa fa-print"></i>
                    &nbsp;Print
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body" id="printable-content">
              <div className="row">
                <div className="col-md-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td style={{ border: "none" }} align="center">
                          <img
                            src="https://demo.limerickbd.com/backend/public/image/artisan-logo.svg"
                            width="30%"
                            alt="Logo"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-sm">
                    <tbody>
                      <tr>
                        <td width="30%">
                          Receipt No: {receiveData.receipt_no}
                          <br />
                          Company: {receiveData.company?.name}
                        </td>
                        <td width="35%" align="right">
                          Work Order Date: {receiveData.work_order_date}
                          <br />
                          Delivery Date: {receiveData.delivery_date}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th>Sl.</th>
                        <th>Product</th>
                        <th>Shape</th>
                        {/* <th>Shape Type</th> */}
                        {/* <th>Order Number</th> */}
                        {/* <th>Company</th> */}
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.product?.name}</td>
                          <td>{item.shape.name}</td>
                          {/* <td>{findShapeTypeName(item.shape_type_id)}</td> */}
                          {/* <td>{item.order_number}</td> */}
                          {/* <td>{findCompanyName(item.company_id)}</td> */}
                          <td align="right">{item.qty}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="3" align="right">
                          Total Quantity
                        </td>
                        <td align="right">
                          <strong>
                            {dataList.reduce((acc, item) => acc + item.qty, 0)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Prepared By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Checked By
                        </td>
                        <td width="30%" align="center">
                          <br />
                          <br />
                          <br />
                          ......................................................
                          <br />
                          Approved By
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProductionWorkOrder;
