import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import ReactDropdown from "../../../Common/ReactDropdown";


const CreateAssign = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});

  const [asset, setAsset] = useState({});
  const [staff, setStaff] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [department, setDepartment] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const resAsset = await Http.get("/asset");
        setAsset(resAsset.data);

        const resStaff = await Http.get("/staff");
        setStaff(resStaff.data);

        const resLocation = await Http.get("/location");
        setLocation(resLocation.data);

        const resFactory = await Http.get("/factory");
        setFactory(resFactory.data);

        const resDepartment = await Http.get("/department");
        setDepartment(resDepartment.data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    getCurrentDateInput();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getCurrentDateInput = () => {
    const dateObj = new Date();

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}-${month}-${day}`;

    setInputs((values) => ({ ...values, ["assign_date"]: shortDate }));
  };

  // Default Load
  // const fetchData = () => {
  //   Http.get("/asset").then((res) => {
  //     setAsset(res.data);
  //   });
  //   Http.get("/staff").then((res) => {
  //     setStaff(res.data);
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/department").then((res) => {
  //     setDepartment(res.data);
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  //   getCurrentDateInput();
  // }, []);
  // End Default Load

  const formSubmit = () => {
    Http.post("/asset-assign", inputs)
      .then((res) => {
        navigate("/asset-assign", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const reactDropdownHandler = (field, prop) => (e) => {
    setInputs((values) => ({
      ...values,
      [field]: e.value[prop],
    }));
  };

  return (
    <div>
      <IsLogin></IsLogin>
      {/* <LoaderComponent /> */}
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Asset Assign</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Asset Assign</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <div className="row">
                  <div className="col-lg">
                    <label>
                      Date <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="assign_date"
                      value={inputs.assign_date || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg">
                    <label>
                      Asset <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="asset_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Asset </option>
                      {Array.from(asset).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={asset} optitle={"name"} onChange={reactDropdownHandler("asset_id", "id")} />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">
                      Staff <i className="fa fa-star required"></i>
                    </label>
                    {/* <select
                      className="form-control"
                      name="hr_staff_basic_info_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Staff</option>
                      {Array.from(staff).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.card_no} - {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={staff} optitle={"name"} onChange={reactDropdownHandler("hr_staff_basic_info_id", "id")} />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Location</label>
                    {/* <select
                      className="form-control"
                      name="location_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Location</option>
                      {Array.from(location).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={location} optitle={"name"} onChange={reactDropdownHandler("location_id", "id")} />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg">
                    <label htmlFor="">Factory</label>
                    {/* <select
                      className="form-control"
                      name="factory_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Factory</option>
                      {Array.from(factory).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={factory} optitle={"name"} onChange={reactDropdownHandler("factory_id", "id")} />
                  </div>
                  <div className="col-lg">
                    <label htmlFor="">Department</label>
                    {/* <select
                      className="form-control"
                      name="department_id"
                      onChange={handleChange}
                    >
                      <option value="">Select Department</option>
                      {Array.from(department).map((item, index) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <ReactDropdown options={department} optitle={"name"} onChange={reactDropdownHandler("department_id", "id")} />
                  </div>
                  <div className="col-lg">
                    <label>
                      Description <i className="fa fa-star required"></i>
                    </label>
                    <textarea
                      className="form-control"
                      name="remarks"
                      value={inputs.remarks || ""}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {/* <div className="col-lg">
                <label>Attachment</label>
                <input
                  type="file"
                  name="attachment"
                  className="form-control"
                  multiple
                />
              </div> */}
                </div>

                <div className="row mt-3">
                  <div className="col-6"></div>
                  <div className="col-3"></div>
                  <div className="col-3">
                    <button
                      className="btn btn-primary btn-block mg-b-10"
                      onClick={formSubmit}
                    >
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateAssign;
