import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import Swal from "sweetalert2";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const ExportScheduleReport = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const [inputs, setInputs] = useState({
    query_date: getCurrentDate(),
  });

  function getCurrentDate() {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fetchData = () => {
    Http.get("/report-leave-report").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const formSubmit = () => {
    Http.get(
      "/movement-slip?from=" +
        inputs.from +
        "&to=" +
        inputs.to +
        "&staff=" +
        inputs.hr_staff_basic_info_id
    ).then((res) => {
      setReceiveData(res.data);
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Export Schedule Report</h5>
          </div>
          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-lg">
                      <input
                        type="date"
                        className="form-control margin-bottom-on-mobile"
                        name="from"
                        value={inputs.query_date || ""}
                        onChange={handleChange}
                        placeholder="From Date"
                      />
                    </div>
                    <div className="col-lg">
                      <button
                        className="btn btn-primary btn-block mg-b-10"
                        onClick={formSubmit}
                      >
                        <i className="fa fa-send mg-r-10"></i> Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <h5 className="text-uppercase text-sm font-weight-bolder">
                      Artisan Ceramics Limited
                    </h5>
                    <p>
                      House-16, Road-07, Sector-01, Uttara, Dhaka
                      <br />
                      <b>
                        Export Schedule Report for the month of September-2024
                      </b>
                      <br />
                      (Casting)
                    </p>
                  </div>
                  <div className="text-right">
                    <h6 className="text-sm font-weight-bolder mb-4">
                      Date: {inputs.query_date}
                    </h6>
                  </div>
                  <div className="table-responsive p-0 pb-2">
                    <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 _datatable">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Month Name
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            Sl. No.
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Name of Customer
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Customer PO/Order
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Artisan Order No.
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Shape
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Cont.
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Total Order Qty. in Pcs for {`Sep'24`}
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            White Ware Ready up to {inputs.query_date}
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Required Qty. for {`Sep'24`}
                          </th>
                          <th
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"
                            colSpan={4}
                          >
                            Decration
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Required Decals Sheets
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Inspection
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Tentative Date of Delivery
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Remarks
                          </th>
                        </tr>
                        <tr>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            BD
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            GL/PL
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            GD
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            White/Mat Black/Colour Glaze
                          </th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                          <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            rowSpan={4}
                          >{`Sep'24`}</td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            01
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Weissestal Home Concepts S.r.l (Thun)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Sweet Order #3030 / Date: 20.06.2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            ACL/TS/POR/044/2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Art 54 & 90
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            2 x {`40'`}
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            02
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Weissestal Home Concepts S.r.l (Thun)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Sweet Order #3030 / Date: 20.06.2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            ACL/TS/POR/044/2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Art 54 & 90
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            2 x {`40'`}
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            03
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Weissestal Home Concepts S.r.l (Thun)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Sweet Order #3030 / Date: 20.06.2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            ACL/TS/POR/044/2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Art 54 & 90
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            2 x {`40'`}
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                            04
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Weissestal Home Concepts S.r.l (Thun)
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Sweet Order #3030 / Date: 20.06.2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            ACL/TS/POR/044/2024
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            Art 54 & 90
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            2 x {`40'`}
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            138880
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            colSpan={7}
                          >
                            Total of Export Qty. in Pcs
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            rowSpan={2}
                          >
                            For {`Sep'24`}
                          </td>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            colSpan={6}
                          >
                            Total of Corporate Target Qty. in Pcs (Sep-24)
                            Approximate
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            colSpan={6}
                          >
                            Total of Local Target Qty. in Pcs (Sep-24)
                            Approximate
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        <tr>
                          <td
                            className="text-uppercase text-dark text-sm font-weight-bolder ps-2"
                            colSpan={7}
                          >
                            Grand Total for the Month of Sep-24
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center">
                            555520
                          </td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                          <td className="text-uppercase text-dark text-sm font-weight-bolder ps-2 text-center"></td>
                        </tr>
                        {/* {receiveData &&
                          receiveData.map((item, index) => (
                            <>
                              <tr key={item.id}>
                                <td className="text-xs">{index + 1}</td>
                                <td className="text-xs" colSpan={13}>
                                  <b>{item.dept_name}</b>
                                </td>
                              </tr>
                              {item.staff.map((item2, index2) => (
                                <tr key={item2.id}>
                                  <td className="text-xs"></td>
                                  <td className="text-xs">{item2.name}</td>
                                  <td className="text-xs">{item.casual}</td>
                                  <td className="text-xs">{item.sick}</td>
                                  <td className="text-xs">{item.earn}</td>
                                  <td className="text-xs">{item.others}</td>
                                  <td className="text-xs">{item2.casual}</td>
                                  <td className="text-xs">{item2.sick}</td>
                                  <td className="text-xs">{item2.earn}</td>
                                  <td className="text-xs">{item2.others}</td>
                                </tr>
                              ))}
                            </>
                          ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportScheduleReport;
