import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../Common/Http";
import { LoaderComponent } from "../../Common/LoaderComponent";
import Apa from "../HrManagement/Staff/Profile/Apa";
import Attendance from "../HrManagement/Staff/Profile/Attendance";
import BillSubmission from "../HrManagement/Staff/Profile/BillSubmission";
import Info from "../HrManagement/Staff/Profile/Info";
import LeaveApplication from "../HrManagement/Staff/Profile/LeaveApplication";
import MovementSlip from "../HrManagement/Staff/Profile/MovementSlip";
import Salary from "../HrManagement/Staff/Profile/Salary";
import Transactions from "../HrManagement/Staff/Profile/Transactions";

const StaffProfile = (props) => {
    const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [apa, setApa] = useState({});
  const [transactions, setTransactions] = useState({});
  const [attendance, setAttendance] = useState({});
  const [todaysAttendanceOrNot, setTodaysAttendanceOrNot] = useState({});
  const [billSubmission, setBillSubmission] = useState({});
  const [leaveApplication, setLeaveApplication] = useState({});
  const [movementSlip, setMovementSlip] = useState({});
  const [salary, setSalary] = useState({});
  const [error, setError] = useState({});
  const [receiveData, setReceiveData] = useState({});
  const [id, setId] = useState({});
  const [device, setDevice] = useState('');
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(false);
  const [attendanceGiven, setAttendanceGiven] = useState(false);

  const fetchData = () => {
    Http.get("/login-staff-detail").then((res) => {
      setId(res.data.id);
      const id = res.data.id;

      Http.get("/staff/" + id + "/edit").then((res) => {
        setReceiveData(res.data);
      });
    });

    Http.get("/me").then((res) => {
      setAttendanceGiven(res.data.data.attendance_given_or_not);
      setLoading(false);
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const salaryLoad = () => {
    Http.get("/salary-staff-info/" + id).then((res) => {
      setSalary(res.data);
    });
  };

  const apaLoad = () => {
    Http.get("/apa-staff-info/" + id).then((res) => {
      setApa(res.data);
    });
  };

  const leaveApplicationLoad = () => {
    Http.get("/leave-application-staff-info/" + id).then((res) => {
      setLeaveApplication(res.data);
    });
  };

  const movementSlipLoad = () => {
    Http.get("/movement-slip-staff-info/" + id).then((res) => {
      setMovementSlip(res.data);
    });
  };

  const billSubmissionLoad = () => {
    Http.get("/bill-submission-staff-info/" + id).then((res) => {
      setBillSubmission(res.data);
    });
  };

  const attendanceLoad = () => {
    Http.get("/attendance-staff-info/" + id).then((res) => {
      setAttendance(res.data);
    });
  };

  const transactionsLoad = () => {
    Http.get("/transactions-staff-info/" + id).then((res) => {
      setTransactions(res.data);
    });
  };

  // Helper function to calculate distance between two coordinates
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) ** 2 + 
              Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
              Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Function to obtain the current geolocation
  const getGeoLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
          error => reject(error)
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const AttendanceForm = () => {
    const [showMovementSlip, setShowMovementSlip] = useState(false);
    const [movementType, setMovementType] = useState(''); // official or personal
    const [remarks, setRemarks] = useState('');
    const [time, setTime] = useState('');

    const formSubmit = async () => {
      try {
        const location = await getGeoLocation();
        const fixedLat = 23.7644357;
        const fixedLng = 90.358958;
        const distance = calculateDistance(location.lat, location.lng, fixedLat, fixedLng);
  
        console.log(`Distance to fixed point is ${distance.toFixed(2)} kilometers.`);
  
        if (distance > 0.01) { // More than 10 meters
          setShowMovementSlip(true); // Show the movement slip form
        } else {
          // Less than 10 meters, submit attendance

          const is_movement_slip = false;
          const dataToSend = { ...inputs, ...location, distance, is_movement_slip};
          const response = await Http.post("/attendance-app-save", dataToSend);
          navigate("/attendance", {
            state: { message: response.data.message, type: "success" }
          });
        }
      } catch (error) {
        console.error("Error obtaining location or sending data:", error);
        setError(error.response ? error.response.data.errors : error);
      }
    };
    const handleMovementSlipSubmit = async () => {
      try {
        const location = await getGeoLocation();
        const is_movement_slip = true;
        const end_time = time;
        const type = movementType;
        const dataToSend = { type, remarks, end_time, ...location, ...inputs, distance, is_movement_slip };
        
        const response = await Http.post("/attendance-app-save", dataToSend);
        
        // Assuming the response contains a `status` field to check for success
        if (response.data.status === true) {
          navigate("/attendance", {
            state: { message: response.data.message, type: "success" }
          });
        } else {
          console.error("Submission failed:", response.data.message);
          setError(response.data.message);
        }
      } catch (error) {
        console.error("Error submitting movement slip:", error);
        setError(error.response ? error.response.data.errors : error);
      }
    };
    

    return (
      <div className="attendance-modal">
        <div className="modal-content">
        {!showMovementSlip && (
          <div className="p-5">
          <h2 className="text-center">You must submit your attendance!</h2>
          <button className="mt-4 btn btn-info btn-block" onClick={formSubmit}>Submit Attendance</button>
          </div>
        )}
          {showMovementSlip && (
            <div className="movement-slip p-5">
              <h3 className="text-center">Movement Slip</h3><hr />
              <div className="form-control">
                <label>Type of Movement : </label>
                <select className="form-control" value={movementType} onChange={e => setMovementType(e.target.value)}>
                  <option value="">Select</option>
                  <option value="official">Official</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
              <div className="form-control mt-4">
                <label>Expected Time to reach Office : </label>
                <input type="time"
                  className="form-control"
                  value={time}
                  onChange={e => setTime(e.target.value)}
                />
              </div>
              <div className="form-control mt-4">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  value={remarks}
                  onChange={e => setRemarks(e.target.value)}
                />
              </div>
              <button className="btn btn-success mt-4" onClick={handleMovementSlipSubmit}>Submit Movement Slip</button>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
<div>
                  <h6 className="card-body-title mg-sm-b-30">Profile</h6>
                  <div className="row row-sm">
                    <div className="col-lg-12 mb-5">
                      <div className="card" style={{ backgroundColor: "#D0D0D0" }}>
                        <div className="wd-100p">
                          <div className="pd-x-20 pd-20">
                            <section className="content">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="box box-primary">
                                    <div className="box-body box-profile">
                                      <img
                                        className="profile-user-img img-responsive img-circle img-thumbnail"
                                        style={{ margin: "0 auto" }}
                                        src="assets/img/default_user.png"
                                        alt="User profile picture"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="box box-primary">
                                    <div className="box-body box-profile text-center">
                                      <ul className="list-group list-group-unbordered">
                                        <li className="list-group-item text-left pt-3">
                                          <h3 className="profile-username text-center">{receiveData.name}</h3>
                                        </li>
                                        <li className="list-group-item text-left pt-2">
                                          <b>Department</b> <span className="pull-right">{receiveData.department ? receiveData.department.name : ""}</span>
                                        </li>
                                        <li className="list-group-item text-left">
                                          <b>Card No</b> <a className="pull-right">{receiveData.card_no}</a>
                                        </li>
                                        <li className="list-group-item text-left">
                                          <b>Type</b> <a className="pull-right">{receiveData.type}</a>
                                        </li>
                                        <li className="list-group-item text-left pb-3">
                                          <b>Status</b> <a className="pull-right">{receiveData.status}</a>
                                        </li>
                                        {/* <li className="list-group-item text-left pb-3">
                                          <b>Device</b> <a className="pull-right">{device}</a>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home"
                            type="button"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Home
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="salary-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#salary"
                            type="button"
                            role="tab"
                            aria-controls="salary"
                            aria-selected="false"
                            onClick={salaryLoad}
                          >
                            Salary
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="apa-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#apa"
                            type="button"
                            role="tab"
                            aria-controls="apa"
                            aria-selected="false"
                            onClick={apaLoad}
                          >
                            APA
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="leave_application-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#leave_application"
                            type="button"
                            role="tab"
                            aria-controls="leave_application"
                            aria-selected="false"
                            onClick={leaveApplicationLoad}
                          >
                            Leave
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="movement_slip-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#movement_slip"
                            type="button"
                            role="tab"
                            aria-controls="movement_slip"
                            aria-selected="false"
                            onClick={movementSlipLoad}
                          >
                            Movement Slip
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="bill_submission-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#bill_submission"
                            type="button"
                            role="tab"
                            aria-controls="bill_submission"
                            aria-selected="false"
                            onClick={billSubmissionLoad}
                          >
                            Bill Submission
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="attendance-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#attendance"
                            type="button"
                            role="tab"
                            aria-controls="attendance"
                            aria-selected="false"
                            onClick={attendanceLoad}
                          >
                            Attendance
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="transactions-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#transactions"
                            type="button"
                            role="tab"
                            aria-controls="transactions"
                            aria-selected="false"
                            onClick={transactionsLoad}
                          >
                            Transactions
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content mt-4" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <Info data={receiveData} />
                        </div>
                        <div className="tab-pane fade show" id="salary" role="tabpanel" aria-labelledby="salary-tab">
                          <Salary data={salary} />
                        </div>
                        <div className="tab-pane fade show" id="apa" role="tabpanel" aria-labelledby="apa-tab">
                          <Apa data={apa} />
                        </div>
                        <div className="tab-pane fade show" id="leave_application" role="tabpanel" aria-labelledby="leave_application-tab">
                          <LeaveApplication data={leaveApplication} />
                        </div>
                        <div className="tab-pane fade show" id="movement_slip" role="tabpanel" aria-labelledby="movement_slip-tab">
                          <MovementSlip data={movementSlip} />
                        </div>
                        <div className="tab-pane fade show" id="bill_submission" role="tabpanel" aria-labelledby="bill_submission-tab">
                          <BillSubmission data={billSubmission} />
                        </div>
                        <div className="tab-pane fade show" id="attendance" role="tabpanel" aria-labelledby="attendance-tab">
                          <Attendance data={attendance} />
                        </div>
                        <div className="tab-pane fade show" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                          <Transactions data={transactions} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>
  );
};

export default StaffProfile;