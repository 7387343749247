import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  var someDate = new Date();
  var date = someDate.setDate(someDate.getDate());
  var defaultDateValue = new Date(date).toISOString().split("T")[0];

  const navigate = useNavigate();

  const { id, type } = useParams();
  const [inputs, setInputs] = useState({
    status: "received",
    date: defaultDateValue,
    challan_date: defaultDateValue,
  });
  const [error, setError] = useState({});
  const [list, setList] = useState({});
  const [product, setProduct] = useState({});
  const [unit, setUnit] = useState({});
  const [location, setLocation] = useState({});
  const [factory, setFactory] = useState({});
  const [lastGrnNo, setLastGrnNo] = useState("000001");

  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const inputInfo = [...list]; // Defined Experience State All Information into List Variable

    inputInfo[index][name] = value;
    setList(inputInfo);
  };

  // const handleReceiveQty = (e, index) => {
  //   const { name, value } = e.target; // Chanages Experience Input Name and Value
  //   const inputInfo = [...list]; // Defined Experience State All Information into List Variable

  //    // Update the corresponding field value
  // inputInfo[index][name] = value;

  // // Calculate accepted_qty as PO Qty - Rejected Qty
  // if (name === "rejected_qty") {
  //   let calculatedReceiveQty =
  //     parseFloat(inputInfo[index].qty) - parseFloat(value);

  //   // Ensure received_qty does not exceed qty
  //   if (calculatedReceiveQty > parseFloat(inputInfo[index].qty)) {
  //     calculatedReceiveQty = parseFloat(inputInfo[index].qty);
  //   }

  //   inputInfo[index].receive_qty = calculatedReceiveQty;
  // }

  // // Calculate total_amount based on the accepted_qty and unit_price
  // inputInfo[index].total_amount =
  //   parseFloat(inputInfo[index].unit_price) *
  //   parseFloat(inputInfo[index].receive_qty);

  // // Update the list state
  // setList(inputInfo);

  //   // if (
  //   //   parseFloat(e.target.getAttribute("data-due_qty")) >=
  //   //   parseFloat(e.target.value)
  //   // ) {
  //   //   inputInfo[index][name] = value;
  //   //   inputInfo[index].total_amount = inputInfo[index].unit_price * value;

  //   // } else {
  //   //   alert("Qty Not Available on Purchase Order");
  //   //   inputInfo[index][name] = 0;
  //   //   inputInfo[index].total_amount = 0; // Reset total_amount if invalid

  //   // }

  //   setList(inputInfo);
  // };

  const handleReceiveQty = (e, index) => {
    const { name, value } = e.target;
    const inputInfo = [...list];

    let inputValue = parseFloat(value);
    const maxQty = parseFloat(inputInfo[index].qty);

    // Ensure that the input value is not less than zero
    if (inputValue < 0) {
      inputValue = 0;
    }

    // Ensure that the input value does not exceed the qty
    if (inputValue > maxQty) {
      inputValue = maxQty;
    }

    // Update the corresponding field value
    inputInfo[index][name] = inputValue;

    // Calculate received_qty as PO Qty - Rejected Qty
    if (name === "rejected_qty") {
      inputInfo[index].accepted_qty = maxQty - inputValue;
    } else if (name === "accepted_qty") {
      inputInfo[index].rejected_qty = maxQty - inputValue;
    }

    // Calculate total_amount based on the valid received_qty and unit_price
    inputInfo[index].total_amount =
      parseFloat(inputInfo[index].unit_price) *
      parseFloat(inputInfo[index].accepted_qty);

    // Update the list state
    setList(inputInfo);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const fetchSPRData = async (purchaseRequisitionArtId) => {
  //   try {
  //     const res = await Http.get(
  //       `/store-purchase-requisition-art/${purchaseRequisitionArtId}`
  //     );
  //     const sprData = res.data;

  //     // Update the inputs with SPR data
  //     setInputs((values) => ({
  //       ...values,
  //       spr_no: sprData.receipt_no,
  //       spr_date: sprData.date,
  //     }));

  //      // Update the list to include the required_qty from SPR API
  //   const updatedList = list.map((item) => {
  //     const matchingSprItem = sprData.items.find(
  //       (sprItem) => sprItem.product_id === item.product_id
  //     );

  //     return {
  //       ...item,
  //       required_qty: matchingSprItem ? matchingSprItem.required_qty : item.required_qty,
  //     };
  //   });

  //   setList(updatedList);

  //   } catch (error) {
  //     console.error("Error fetching SPR data:", error);
  //   }
  // };

  const fetchSPRData = async (purchaseRequisitionArtId) => {
    try {
      const res = await Http.get(
        `/store-purchase-requisition-art/${purchaseRequisitionArtId}`
      );
      const sprData = res.data;

      // Update the inputs with SPR data
      setInputs((values) => ({
        ...values,
        spr_no: sprData.receipt_no,
        spr_date: sprData.date,
        store_dept_id: sprData.created_by.depot_id,
      }));

      // Update the list to include the required_qty from SPR API
      setList((prevList) =>
        prevList.map((item) => {
          const matchingSprItem = sprData.items.find(
            (sprItem) => sprItem.product_id === item.product_id
          );

          return {
            ...item,
            required_qty: matchingSprItem
              ? matchingSprItem.required_qty
              : item.required_qty,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching SPR data:", error);
    }
  };

  // const fetchData = () => {
  //   const listInfo = [];
  //   if (type == "po") {
  //     Http.get("/work-order/" + id).then((res) => {
  //       const listInfo = [];
  //       Object.keys(res.data.items).forEach((key) => {
  //         listInfo.push({
  //           item_id: res.data.items[key].id,
  //           product_id: res.data.items[key].product_id,
  //           unit_id: res.data.items[key].unit_id,
  //           unit_qty: res.data.items[key].unit.qty,
  //           unit_price: res.data.items[key].unit_price,
  //           qty: res.data.items[key].qty,
  //           actual_qty: res.data.items[key].actual_qty,
  //           desc: "",
  //           prev_rcv:
  //             parseFloat(res.data.items[key].received_qty) > 0
  //               ? res.data.items[key].received_qty
  //               : "0",
  //         });
  //       });
  //       setList(listInfo);
  //     });
  //   } else if (type == "dp") {
  //     Http.get("/direct-purchase/" + id).then((res) => {
  //       console.log(res.data);
  //       const listInfo = [];
  //       Object.keys(res.data.items).forEach((key) => {
  //         listInfo.push({
  //           item_id: res.data.items[key].id,
  //           product_id: res.data.items[key].product_id,
  //           unit_id: res.data.items[key].unit_id,
  //           unit_qty: res.data.items[key].product.unit.qty,
  //           unit_price: res.data.items[key].price,
  //           qty: res.data.items[key].qty,
  //           receive_qty: res.data.items[key].qty,
  //           actual_qty: res.data.items[key].qty,
  //           desc: "",
  //           prev_rcv:
  //             parseFloat(res.data.items[key].received_qty) > 0
  //               ? res.data.items[key].received_qty
  //               : "0",
  //         });
  //       });
  //       setList(listInfo);
  //       // If purchase_requisition_art_id is available, fetch the SPR data
  //       if (res.data.purchase_requisition_art_id) {
  //         fetchSPRData(res.data.purchase_requisition_art_id);
  //       }
  //     });
  //   }

  //   Http.get("/product").then((res) => {
  //     setProduct(res.data);
  //   });
  //   Http.get("/last-grn-no").then((res) => {
  //     setLastGrnNo(res.data);
  //   });
  //   Http.get("/last-code-increment-by-one/grns/challan_no/CH").then((res) => {
  //     setInputs((values) => ({ ...values, ["challan_no"]: res.data }));
  //   });
  //   Http.get("/location").then((res) => {
  //     setLocation(res.data);
  //   });
  //   Http.get("/factory").then((res) => {
  //     setFactory(res.data);
  //   });
  //   Http.get("/unit").then((res) => {
  //     setUnit(res.data);
  //   });
  // };

  const fetchData = async () => {
    const listInfo = [];
    if (type === "po") {
      const res = await Http.get("/work-order/" + id);
      const listInfo = res.data.items.map((item) => ({
        item_id: item.id,
        product_id: item.product_id,
        unit_id: item.unit_id,
        unit_qty: item.unit.qty,
        unit_price: item.unit_price,
        qty: item.qty,
        actual_qty: item.actual_qty,
        desc: "",
        prev_rcv: parseFloat(item.received_qty) > 0 ? item.received_qty : "0",
      }));
      setList(listInfo);
    } else if (type === "dp") {
      const res = await Http.get("/direct-purchase/" + id);
      const listInfo = res.data.items.map((item) => ({
        item_id: item.id,
        product_id: item.product_id,
        unit_id: item.unit_id,
        unit_qty: item.product.unit.qty,
        unit_price: item.price,
        qty: item.qty,
        accepted_qty: item.qty,
        actual_qty: item.qty,
        desc: "",
        prev_rcv: parseFloat(item.received_qty) > 0 ? item.received_qty : "0",
        total_amount: item.price * item.qty, // Calculate total_amount initially
      }));
      setList(listInfo);

      // Set the po_no based on the id fetched from /direct-purchase/
      setInputs((values) => ({
        ...values,
        po_no: res.data.id, // Assuming the direct purchase ID is used as po_no
      }));

      // If purchase_requisition_art_id is available, fetch the SPR data
      if (res.data.purchase_requisition_art_id) {
        await fetchSPRData(res.data.purchase_requisition_art_id); // Wait for SPR data to be fetched
      }
    }

    // Fetch other necessary data
    const productRes = await Http.get("/product");
    setProduct(productRes.data);
    const grnNoRes = await Http.get("/last-grn-no");
    setLastGrnNo(grnNoRes.data);
    const challanRes = await Http.get(
      "/last-code-increment-by-one/grns/challan_no/CH"
    );
    setInputs((values) => ({ ...values, challan_no: challanRes.data }));
    const locationRes = await Http.get("/location");
    setLocation(locationRes.data);
    const factoryRes = await Http.get("/factory");
    setFactory(factoryRes.data);
    const unitRes = await Http.get("/unit");
    setUnit(unitRes.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const formSubmit = () => {
  //   inputs.items = list;
  //   inputs.work_order_id = id;
  //   inputs.grn_no = lastGrnNo;
  //   inputs.type = type;
  //   inputs.id = id;

  //   Http.post("/grn", inputs)
  //     .then((res) => {
  //       navigate("/direct-purchase", {
  //         state: { message: res.data.message, type: "success" },
  //       });
  //     })
  //     .catch(function (e) {
  //       setError(e.response.data.errors);
  //     });
  // };

  const formSubmit = () => {
    // Prepare the items array with required_qty as spr_qty and qty as po_qty
    const itemsWithUpdatedFields = list.map((item) => ({
      ...item,
      spr_qty: item.required_qty, // Map required_qty to spr_qty
      po_qty: item.qty, // Map qty to po_qty
    }));

    // Update the inputs object to include the modified items
    inputs.items = itemsWithUpdatedFields;
    inputs.work_order_id = id;
    inputs.grn_no = lastGrnNo;
    inputs.type = type;
    inputs.id = id;

    Http.post("/grn", inputs)
      .then((res) => {
        navigate("/direct-purchase", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Receive Product</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Receive Product</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date || ""}
                  onChange={handleChange}
                  placeholder="Input Date"
                />
              </div>
              <div className="col-lg">
                <label>Challan Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="challan_date"
                  value={inputs.challan_date || ""}
                  onChange={handleChange}
                  placeholder="Input Challan Date"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>GRN No</label>
                <input
                  type="text"
                  className="form-control"
                  name="grn_no"
                  value={lastGrnNo || "00001"}
                  onChange={handleChange}
                  placeholder="Input GRN No"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>SPR No</label>
                <input
                  type="text"
                  className="form-control"
                  name="spr_no"
                  value={inputs.spr_no || ""}
                  disabled
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>SPR Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="spr_date"
                  value={inputs.spr_date || ""}
                  disabled
                />
              </div>

              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Challan No</label>
                <input
                  type="text"
                  className="form-control"
                  name="challan_no"
                  value={inputs.challan_no || "CH00001"}
                  onChange={handleChange}
                  placeholder="Input Challan No"
                />
              </div>
              {/* <div className="col-lg">
                <label htmlFor="">Location</label>
                <select
                  className="form-control"
                  name="company_location_id"
                  onChange={handleChange}
                >
                  <option value="">Select Location</option>
                  {Array.from(location).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label htmlFor="">Factory</label>
                <select
                  className="form-control"
                  name="factory_id"
                  onChange={handleChange}
                >
                  <option value="">Select Factory</option>
                  {Array.from(factory).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Status</label>
                <select
                  className="form-control"
                  name="status"
                  onChange={handleChange}
                >
                  <option
                    value="received"
                    selected={inputs.status === "received" ? true : false}
                  >
                    Received
                  </option>
                  {/* <option
                    value="active"
                    selected={inputs.status === "active" ? true : false}
                  >
                    Active
                  </option>
                  <option
                    value="inactive"
                    selected={inputs.status === "inactive" ? true : false}
                  >
                    inactive
                  </option> */}
                  <option
                    value="completed"
                    selected={inputs.status === "completed" ? true : false}
                  >
                    Completed
                  </option>
                </select>
              </div>
            </div>

            <div className="row mt-3">
              <table className="table table-sm align-items-center mb-0">
                <thead>
                  <tr>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Product
                    </th>
                    {/* <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Unit
                    </th> */}
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Unit Price
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      SPR Qty
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      PO Qty
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Prev Receive
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Moisture
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Rejected Receive
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Accepted Qty
                    </th>
                    <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(list).map((x, i) => (
                    <tr key={i}>
                      <td>
                        <select
                          className="form-control"
                          name="product_id"
                          disabled
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="">Select Product</option>
                          {Array.from(product).map((item, index) => (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={x.product_id === item.id ? true : false}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      {/* <td>
                        <select
                          className="form-control"
                          name="unit_id"
                          disabled
                          onChange={(e) => handleInputChange(e, i)}
                        >
                          <option value="">Select Unit</option>
                          {Array.from(unit).map((item, index) => (
                            <option
                              value={item.id}
                              key={item.id}
                              selected={x.unit_id === item.id ? true : false}
                            >
                              {item.name} - ({item.qty})
                            </option>
                          ))}
                        </select>
                      </td> */}
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="unit_price"
                          disabled
                          value={x.unit_price || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="required_qty"
                          disabled
                          value={x.required_qty || ""}
                          // onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="qty"
                          disabled
                          value={x.qty || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          name="prev_rcv"
                          disabled
                          value={x.prev_rcv || ""}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </td>
                      <td>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            name="moisture"
                            value={x.moisture || ""}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                          <span className="input-group-text">%</span>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="rejected_qty"
                          value={x.rejected_qty || ""}
                          data-due_qty={
                            parseFloat(x.qty) - parseFloat(x.prev_rcv)
                          }
                          onChange={(e) => handleReceiveQty(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="accepted_qty"
                          value={x.accepted_qty || ""}
                          data-due_qty
                          onChange={(e) => handleReceiveQty(e, i)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name="total_amount"
                          value={x.total_amount || ""}
                          data-due_qty
                          onChange={(e) => handleReceiveQty(e, i)}
                        />
                      </td>
                      {/* <td>
                        <textarea className="form-control" name="desc" value={x.desc || ""} onChange={(e) => handleInputChange(e, i)}></textarea>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
