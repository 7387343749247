import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Show = () => {
  const [receiveData, setReceiveData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [alert, setAlert] = useState(null);
  const location = useLocation();

  const fetchData = () => {
    Http.get("/others-receive/" + id).then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const receiveWorkOrder = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You wan't to create an Purchase Order based on this quotation!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Create Purchase Order!",
    }).then((result) => {
      if (result.isConfirmed) {
        inputs.circular_rfq_id = id;
        inputs.work_order_id = receiveData.id;
        inputs.work_order_items_id = receiveData.items;

        Http.post("/create-purchase-order/", inputs)
          .then((res) => {
            Swal.fire("Purchase Order! has been Created.", "success");
            navigate("/final-quotation", {
              state: { message: res.data.message, type: "success" },
            });
          })
          .catch(function (e) {
            setError(e.response.data.errors);
          });
      }
    });
  };
  const print = () => {
    window.print();
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      {/* <style>
        {`
              @media print {
                  body * { visibility: hidden; font-size:10px;}
                  .no-print { visibility: hidden !important; }
                  #printable-content, #printable-content * { visibility: visible; }
                  #printable-content { position: absolute; left: 0; top: 0; width: 100%; }
                  .main-card { padding: 0px; }
                  .am-pagebody { padding: 0px; }
                  .am-mainpanel { margin-top: 0px; }
                  .signature_section .table td{ border-bottom: 0;}
              }
              .main-card { padding: 40px; }
            `}
      </style> */}
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">Others Receive</h5>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />
            <div className="card pd-20 pd-sm-40">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 padding">
                {/* <div className="card">
                  <div className="card-body"> */}
                <div className="card-header noPrint">
                  <div className="row">
                    <div className="col-md-7">
                      <h4 className="card-title">
                        Others Receive - {receiveData.receipt_no}
                      </h4>
                    </div>
                    <div className="col-md-5 text-right">
                      <button className="btn btn-default" onClick={print}>
                        {" "}
                        <i className="fa fa-print"></i>
                        &nbsp;Print
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td style={{ border: "none" }} align="center">
                            <img
                              src="https://demo.limerickbd.com/backend/public/image/artisan-logo.svg"
                              width="30%"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-sm-12">
                    <h5 className="mb-3">Others Receive :</h5>
                    <div>Date : {receiveData.date}</div>
                    <div>
                      Location :
                      {receiveData.company_location
                        ? receiveData.company_location.name
                        : ""}
                    </div>
                    <div>
                      Factory :
                      {receiveData.factory ? receiveData.factory.name : ""}
                    </div>
                    <div>
                      Department :
                      {receiveData.department
                        ? receiveData.department.name
                        : ""}
                    </div>
                    <div>Description : {receiveData.description}</div>
                    <div>
                      Created By :
                      {receiveData.created_by
                        ? receiveData.created_by.name
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="table-responsive-sm">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="center">#</th>
                        <th>Product</th>
                        <th className="text-right">Qty</th>
                        <th className="text-right">Price</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiveData.items
                        ? receiveData.items.map((item, index) => (
                            <tr key={item.id}>
                              <td className="center">{index + 1}</td>
                              <td className="left strong">
                                {item.product ? item.product.name : ""}
                              </td>
                              <td className="text-right">{item.qty}</td>
                              <td className="text-right">{item.unit_price}</td>
                              <td>{item.desc}</td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                </div>
                {/* </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
