import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import Select from "react-select";
import { ProgressSpinner } from "primereact/progressspinner";

const Create = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { checkRequiredFields } = useRequiredFields();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const res = await Http.get("/last-code-increment-by-one/shape_types/code/SHT");
        setInputs({
          code: res.data,
        });
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const formSubmit = () => {
    if (checkRequiredFields()) {
      Http.post("/createShapeType", inputs)
        .then((res) => {
          navigate("/shape-type", {
            state: { message: res.data.message, type: "success" },
          });
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <IsLogin />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Shape Type</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Create Shape Type Form</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>

            {loading ? (
              <ProgressSpinner />
            ) : (
              <div>
                <div className="row">
                  <div className="col-lg">
                    <label className="font-weight-bold">
                      Name <i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Input Shape Type Name"
                      data-required
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Code<i className="fa fa-star required"></i>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      value={inputs.code || ""}
                      onChange={handleChange}
                      placeholder="Input Receipt No."
                      data-required
                    />
                  </div>
                  <div className="col-lg mg-t-10 mg-lg-t-0">
                    <label className="font-weight-bold">
                      Status <i className="fa fa-star required"></i>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="status"
                      options={[{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]}
                      onChange={(option) => setInputs({ ...inputs, status: option.value })}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-3">
                    <button className="btn btn-primary btn-block mg-b-10" onClick={formSubmit}>
                      <i className="fa fa-send mg-r-10"></i> Create
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Create;
