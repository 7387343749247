import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Http from "../../../Common/Http";
import Swal from "sweetalert2";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const ReceiveProductionTransfer = () => {
  const { id } = useParams(); // Get transfer id from URL
  const navigate = useNavigate();

  const [transferData, setTransferData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [staffType, setStaffType] = useState(null); // Add this state to store the staff type
  const [error, setError] = useState({});
  const [items, setItems] = useState([]);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);


  useEffect(() => {
    fetchTransferData();
  }, [id]);

  // const fetchTransferData = () => {
  //   setLoading(true);
  //   Http.get(`/production-transfer/${id}`)
  //     .then((res) => {
  //       setTransferData(res.data);
  //       // Pre-fill receive_qty with delivery_qty
  //       const updatedItems = res.data.transfer.items.map((item) => ({
  //         ...item,
  //         receive_qty: item.receive_qty? item.receive_qty: item.delivery_qty, // Default value for receive_qty
  //       }));
  //       setItems(updatedItems);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching transfer data:", err);
  //       Swal.fire("Error!", "Failed to load transfer details", "error");
  //     })
  //     .finally(() => setLoading(false));
  // };

  const fetchTransferData = async () => {
    setLoading(true);
    try {
      // Fetch staff type
      const staffResponse = await Http.get("/staff-type-check");
      setStaffType(staffResponse.data);
  
      // Fetch transfer data
      const res = await Http.get(`/production-transfer/${id}`);
      setTransferData(res.data);
  
      // Filter items based on staff type and to_department
      const filteredItems = res.data.transfer.items.filter((item) => {
        const department = departments.find((dept) => dept.id === item.to_department);
        return department && department.user_type === staffResponse.data;
      });
  
      // Pre-fill receive_qty with delivery_qty if not set
      const updatedItems = filteredItems.map((item) => ({
        ...item,
        receive_qty: item.receive_qty ? item.receive_qty : item.delivery_qty,
      }));
      setItems(updatedItems);
    } catch (err) {
      console.error("Error fetching data:", err);
      Swal.fire("Error!", "Failed to load data", "error");
    } finally {
      setLoading(false);
    }
  };
  

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    let inputValue = parseFloat(value);

    // Validation: Ensure receive_qty can't be 0 or greater than delivery_qty
    if (name === "receive_qty") {
      if (inputValue <= 0) {
        Swal.fire("Invalid Input", "Receive quantity cannot be 0 or less.", "warning");
        return;
      }
      if (inputValue > updatedItems[index].delivery_qty) {
        Swal.fire(
          "Invalid Input",
          `Receive quantity cannot exceed the delivered quantity: ${updatedItems[index].delivery_qty}.`,
          "warning"
        );
        return;
      }
    }

    updatedItems[index][name] = inputValue;
    setItems(updatedItems);
  };

  // const formSubmit = () => {
  //   const updatedTransfer = {
  //     ...transferData.transfer,
  //     requisition_id: transferData.finished_good.requisition_id, // Include the requisition_id
  //     production_date: transferData.finished_good.production_date, // Include the requisition_id
  //     items: items.map((item) => ({
  //       id: item.id,
  //       product_id: item.product.id, // Use product_id directly from the product object
  //       receive_qty: item.receive_qty || 0,
  //       shape_id: item.shape_id,
  //       shape_type_id: item.shape_type_id,
  //       remarks_receive: item.remarks_receive || "",
  //       from_department: item.from_department,
  //       to_department: item.to_department || "",
  //       decoration: item.decoration || "",
  //       remarks: item.remarks || "",
  //     })),
  //   };

  //   Http.put(`/production-transfer/${id}`, updatedTransfer)
  //     .then((res) => {
  //       Swal.fire("Success!", "Transfer received successfully!", "success");
  //       navigate("/production-transfer-art", {
  //         state: { message: res.data.message, type: "success" },
  //       });
  //     })
  //     .catch((err) => {
  //       console.error("Error updating transfer:", err);
  //       setError(err.response?.data?.errors || {});
  //       Swal.fire("Error!", "Failed to update transfer", "error");
  //     });
  // };

  const formSubmit = () => {
    const updatedTransfer = {
      ...transferData.transfer,
      requisition_id: transferData.finished_good.requisition_id, // Ensure requisition_id is included
      production_date: transferData.finished_good.production_date, // Include production date
      items: items.map((item) => {
        // Find the existing item data for merging
        const existingItem = transferData.transfer.items.find(i => i.id === item.id);
  
        // Merge the existing item data with the new item data
        return {
          ...existingItem, // Use existing item data as default
          ...item, // Override with new values if provided
          product_id: item.product.id, // Ensure product_id is set correctly
          receive_qty: item.receive_qty || item.delivery_qty, // Default to delivery_qty if receive_qty is not set
          loss_type: transferData.finished_good.items.find(fgItem => fgItem.id === item.id)?.loss_type || null, // Use original value if not provided
          repair_type: transferData.finished_good.items.find(fgItem => fgItem.id === item.id)?.repair_type || null, // Use original value if not provided
          qty: transferData.finished_good.items.find(fgItem => fgItem.id === item.id)?.qty || 0,
          loss_qty: transferData.finished_good.items.find(fgItem => fgItem.id === item.id)?.loss_qty || 0,
       
        };
      }),
    };
  
    Http.put(`/production-transfer/${id}`, updatedTransfer)
      .then((res) => {
        Swal.fire("Success!", "Transfer received successfully!", "success");
        navigate("/transfer-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch((err) => {
        console.error("Error updating transfer:", err);
        setError(err.response?.data?.errors || {});
        Swal.fire("Error!", "Failed to update transfer", "error");
      });
  };
  
  const getDepartmentName = (id) => {
    const departments = [
      { id: 1, name: "BODY" },
      { id: 2, name: "JIGGER" },
      { id: 3, name: "CASTING" },
      { id: 4, name: "FINISHING" },
      // Add more departments here as needed
    ];
    const dept = departments.find((dept) => dept.id === id);
    return dept ? dept.name : "N/A";
  };

  return (
    <div>
      {loading && <LoaderComponent />}
      {transferData && (
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">
              Receive Production Transfer - {transferData.transfer.receipt_no}
            </h5>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              <h6 className="card-body-title">Receiving Form</h6>

              {/* Display Transfer Information */}
              <div className="row">
                <div className="col-lg">
                  <label>Receipt No</label>
                  <input
                    type="text"
                    className="form-control"
                    value={transferData.transfer.receipt_no}
                    disabled
                  />
                </div>
                <div className="col-lg">
                  <label>Transfer Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={transferData.transfer.transfer_date}
                    disabled
                  />
                </div>
              </div>

              {/* Display Items */}
              <div className="row mt-3">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Delivered Qty</th>
                      <th>Received Qty</th>
                      {/* <th>To Department</th> */}
                      <th>Remarks Receive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.product.name}</td>
                        <td>{item.delivery_qty}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="receive_qty"
                            value={item.receive_qty || ""}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          {error[`items.${index}.receive_qty`] && (
                            <div className="text-danger small">
                              {error[`items.${index}.receive_qty`]}
                            </div>
                          )}
                        </td>
                        {/* <td>{getDepartmentName(item.to_department)}</td> */}
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="remarks_receive"
                            value={item.remarks_receive || ""}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Submit Button */}
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary btn-block" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiveProductionTransfer;
