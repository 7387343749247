import $ from "jquery"; // Import jQuery
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const Create = () => {
  const navigate = useNavigate();
  // const { id } = useParams();

  const [inputs, setInputs] = useState({
    receipt_no: "PT002",
    production_work_order_id: "",
    date: "",
    receive_date: "",
    copy_type: "",
    purchase_type: "",
    from_department: "",
    to_department: "",
    status: "requested",
    remarks: "",
  });

  const [list, setList] = useState([]);
  const [error, setError] = useState({});
  const [product, setProduct] = useState([]);
  const [planningOptions, setPlanningOptions] = useState([]);
  const [role, setRole] = useState("");
  const [itemLoading, setItemLoading] = useState(false);
  const [departments, setDepartments] = useState([
    { id: 1, name: "BODY", user_type: "body" },
    { id: 2, name: "JIGGER", user_type: "jigger" },
    { id: 3, name: "CASTING", user_type: "casting" },
    { id: 4, name: "FINISHING", user_type: "finishing" },
    { id: 5, name: "BK", user_type: "bk" },
    { id: 6, name: "GLAZING", user_type: "glazing" },
    { id: 7, name: "GK", user_type: "gk" },
    { id: 8, name: "SELECTION", user_type: "selection" },
    { id: 9, name: "DECORATION LOCAL", user_type: "decoration_local" },
    { id: 10, name: "DECORATION EXPORT", user_type: "decoration_export" },
    { id: 11, name: "DFW", user_type: "dfw" },
    { id: 12, name: "PACKING LOCAL", user_type: "packing_local" },
    { id: 13, name: "PACKING EXPORT", user_type: "packing_export" },
    { id: 14, name: "DSTORE", user_type: "dstore" },
    { id: 15, name: "GSTORE", user_type: "gstore" },
    { id: 16, name: "MOULD", user_type: "mould" },
    { id: 17, name: "PSTORE", user_type: "pstore" },
    { id: 18, name: "PLANNING", user_type: "planning" },
    { id: 19, name: "SAGGAR", user_type: "saggar" },
  ]);

  // Fetch the requisition data by ID and populate the form inputs
  // const fetchData = () => {
  //   Http.get("/product-list/all").then((res) => {
  //     setProduct(res.data);
  //   });

  //   Http.get("/staff-type-check").then((res) => {
  //     setRole(res.data);
  //   });

  //   Http.get("/planning")
  //     .then((res) => {
  //       setPlanningOptions(res.data); // Set planning options
  //     })
  //     .catch((e) => {
  //       console.error("Error fetching planning options:", e);
  //     });
  // };

  const fetchData = async () => {
    try {
      const resProduct = await Http.get("/product-list/all");
      setProduct(resProduct.data);

      const resRole = await Http.get("/staff-type-check");
      setRole(resRole.data);

      const resPlanning = await Http.get("/planning");
      const planningData = resPlanning.data;
      setPlanningOptions(planningData);

      if (planningData.length > 0) {
        // Automatically select the first planning and set in inputs
        const firstPlanning = planningData[0];
        setInputs((prevInputs) => ({
          ...prevInputs,
          planning_id: firstPlanning.id, // Assuming the ID field for planning
        }));

        // Load items for the first planning
        handlePlanningChange(firstPlanning.id);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    $("body").addClass("collapse-menu");
    // $("body").toggleClass("show-left");
    fetchData();

    // // Set `to_department` based on `role`
    // if (role === "jigger") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department:
    //       departments.find((dept) => dept.name === "FINISHING")?.id || "",
    //   }));
    // } else if (role === "casting") {
    //   setInputs((prevInputs) => ({
    //     ...prevInputs,
    //     to_department: departments.find((dept) => dept.name === "BK")?.id || "",
    //   }));
    // }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...list];
    let inputValue = parseFloat(value) || 0;

    // Update the specific field with the input value
    updatedList[index][name] = inputValue;

    // Retrieve the necessary fields for calculation and validation
    const opening_balance = parseFloat(updatedList[index].opening_balance) || 0;
    const production_qty = parseFloat(updatedList[index].production_qty) || 0;
    const transfer_qty = parseFloat(updatedList[index].transfer_qty) || 0;

    // Validation: production_qty can't be more than opening_balance
    if (name === "production_qty" && production_qty > opening_balance) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Production quantity cannot exceed opening balance.",
      });
      updatedList[index].production_qty = opening_balance;
    }

    // Validation: transfer_qtycan't be more than opening_balance
    if (name === "transfer_qty" && transfer_qty > opening_balance) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Delivery quantity cannot exceed opening balance.",
      });
      updatedList[index].transfer_qty = opening_balance;
    }

    // Calculate closing_balance
    updatedList[index].closing_balance =
      opening_balance + production_qty - transfer_qty;

    setList(updatedList);
  };

  const formSubmit = () => {
    const formData = {
      ...inputs,
      items: list,
    };

    Http.put(`/requisition-art/`, formData)
      .then((res) => {
        navigate("/requisition-art", {
          state: { message: res.data.message, type: "success" },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const handlePlanningChange = async (planningId) => {
    if (!planningId) return;

    setInputs((prevInputs) => ({
      ...prevInputs,
      planning_id: planningId, // Update planning_id in inputs
    }));

    try {
      // Fetch data based on selected planning ID
      const response = await Http.get(`/planning/${planningId}`);

      // Update list with items from the response
      const planningItems = response.data.items.map((item) => ({
        id: item.id,
        product_id: item.product_id,
        product_name: item.product.name,
        shape: item.shape?.name,
        production_work_order: item.production_work_order?.receipt_no,
        company: item.production_work_order?.company?.name,
        product_code: item.product.code,
        opening_balance: 0, // Adjust based on requirement or add additional fields as needed
        weight: item.weight, // Example if qty is used as weight
        production_qty: item.qty,
        transfer_qty: 0,
        closing_balance: item.qty, // Example calculation; adjust if necessary
        remarks_delivery: "", // Set to empty or adjust if needed
      }));

      setList(planningItems);
    } catch (error) {
      console.error("Error fetching products for planning:", error);
      Swal.fire(
        "Error!",
        "Failed to fetch products for the selected planning.",
        "error"
      );
    }
  };

  const cols = [{ field: "name", header: "Item" }];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Producation and Deliver</h5>
        </div>
        <div className="am-pagebody  row d-flex justify-content-evenly">
          <div className="card pd-20 pd-sm-40 col-11">
            <h6 className="card-body-title mg-sm-b-30">
              Production and Delivery
            </h6>
            <p>Transfer To Department {inputs.to_department}</p>
            <div className="row">
              <div className="col-lg">
                <label>Receipt No</label>
                <input
                  type="text"
                  className="form-control"
                  name="receipt_no"
                  value={inputs.receipt_no}
                  disabled
                />
              </div>

              <div className="col-lg">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={inputs.date}
                  disabled
                />
              </div>
              <div className="col-lg">
                <label>Planning</label>
                <select
                  className="form-control"
                  value={inputs.planning_id || ""} // Bind to inputs
                  onChange={(e) => handlePlanningChange(e.target.value)}
                >
                  <option value="">Select Planning</option>
                  {planningOptions.map((planning) => (
                    <option key={planning.id} value={planning.id}>
                      {planning.receipt_no}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="row">
                <label>Transfer To Department {inputs.to_department}</label>
                
              </div> */}

              <div className="col-lg">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  value={inputs.remarks}
                />
              </div>
            </div>

            {/* Item List */}
            <div className="row mt-3">
              <div className="card-solid">
                <div className="card-header with-border text-center">
                  <h5 className="card-title mb-0">Items in the Production</h5>
                </div>
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Opening Balance</th>
                    <th>Weight</th>
                    <th>Production Qty</th> {/* opening + rcved   */}
                    <th>Transfer Qty</th>
                    <th>Return Qty</th>
                    <th>Closing Balance</th> {/* total p - total t */}
                  </tr>
                </thead>
                <tbody>
                  {list.length < 1 ? (
                    <tr>
                      <td colSpan={13} className="text-center">
                        Please add products
                      </td>
                    </tr>
                  ) : (
                    list.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.product_name} ({item.shape}) || {item.company} #
                          {item.production_work_order}
                        </td>

                        <td>{item.opening_balance}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="weight"
                            value={item.weight}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="production_qty"
                            value={item.production_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="transfer_qty"
                            value={item.transfer_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name="return_qty"
                            value={item.return_qty}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </td>
                        <td>{item.closing_balance}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {list.length > 0 && (
              <div className="row mt-3">
                <div className="col-3">
                  <button className="btn btn-primary" onClick={formSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
